import { prop, ArrayOf } from "ddt/DDmodel";
import { Datensatz, IDatensatz } from "../ds";
import { IFoto, Foto, fotoDB } from "../fotos/foto";
import { DDindexedArray, ArrayIndex } from "ddt/DDindexedArray";
import { ItemDB, DbType } from "../item-db";

export interface ITeam extends IDatensatz {
	status		: string
	name		: string
	abbrev		: string
	logo		: string
	logosizes	: string
	
	foto		: IFoto
}


/**
 * Hilfsfunktion um Abkürzungsinformationen aus einem Teamnamen zu entfernen
 *
 * @param string name
 */
function cleanName( name : string ) : string {
	return name.replace( /\{\d\.?|\}/g, '' );
}
	
export function shortTeamname( name : string, maxChars : number = 99 ) : string {

	if ( name && cleanName(name).length > maxChars ) {

		// Abkürzen nach Vorgaben
		// '{1.endung}' ab hier kürzen
		// . : Optionales Abschlusszeichen
		// 1 : Priorität / Reihenfolge der Kürzung
		let abbrFound = true;
		let abbr = 1;
		while ( abbrFound && ( cleanName(name).length > maxChars ) ) {
			abbrFound = false;
			let regEx = new RegExp( '\\{' + abbr + '(\\.?)[^}]+\\}', 'g' )
			let m = name.match( regEx )
			if ( m ) {
				name = name.replace( regEx, '$1' );
				abbrFound = true;
			}
			abbr++;
		}

		// alle Vorgaben entfernen
		name = cleanName(name);
		// doppelte Leerzeichen entfernen
		name = name.replace( /[ ]{2,}/, ' ' );

		let words = name.trim().split( ' ' );

		// falls immer noch zu lang: von hinten nach vorne Wörter nach erstem Buchstaben kürzen
		for ( let i = words.length - 1;  i >= 0; i--) {
			if ( words.join( ' ' ).length > maxChars) {
				words[i] = words[i].replace( /([A-Z])[a-z,ßäöü-]+\.?/, '$1.' );
			} else {
				break;
			}
		}

		// falls immer noch zu lang: Punkte weglassen
		if ( words.join( ' ' ).length > maxChars ) {
			for ( let i = 0, len = words.length;  i < len; i++ ) {
				words[i] = words[i].replace( '.', '' );
			}
		}

		// falls immer noch zu lang: von hinten nach vorne die Leerzeichen weglassen,
		// d.h. die letzten beiden Wörter zusammenkleben
		while ( words.length > 1 && (words.join( ' ' ).length > maxChars) ) {
			words[ words.length - 2] += words[ words.length - 1];
			words.length = words.length - 1;
		}

		name = words.join( ' ' );

	} else {
		name = cleanName(name)
	}
	
	return name;
}


export class Team extends Datensatz implements ITeam {

	/** default: von User angelegtes Team (Gastteam) */
	static st_neu		= 'e';
	/** vom Admin angelegtes Team, oder über Anmeldung angelegtes Team */
	static st_vorlaeufig	= 'v';
	/** aktuell aktives Team (nicht Freundschaftsspiele) */
	static st_aktiv		= 'a';
	/** ehemals aktive Teams */
	static st_inaktiv	= 'i';
	/** Platzhalterteams, Testteams */
	static st_helper		= 'h';

	/** Feld: Status */
	@prop()	status		: string

	/** Feld: Name des Spielorts */
	@prop()	name		: string

	/** Feld: Abkürzungs.Definition */
	@prop()	abbrev		: string

	/** Feld: Name des Logos */
	@prop()	logo		: string

	/** Feld: verfügbare Größen des Logos (kommasepariert und zero-padded), nur wenn mehrere vorhanden */
	@prop()	logosizes	: string
	
	@prop( () => Foto)
	foto				: Foto
	
	shortname( maxChars : number ) : string {
		
		let name = this.name;

		if ( name && name.length > maxChars ) {

			// Abkürzung vorhanden?
			if ( this.abbrev ) {
				name = this.abbrev;
			}

			// Abkürzen nach Vorgaben
			// '{1.endung}' ab hier kürzen
			// . : Optionales Abschlusszeichen
			// 1 : Priorität / Reihenfolge der Kürzung
			let abbrFound = true;
			let abbr = 1;
			while ( abbrFound && ( this.cleanName(name).length > maxChars ) ) {
				abbrFound = false;
				let regEx = new RegExp( '\\{' + abbr + '(\\.?)[^}]+\\}', 'g' )
				let m = name.match( regEx )
				if ( m ) {
					name = name.replace( regEx, '$1' );
					abbrFound = true;
				}
				abbr++;
			}

			// alle Vorgaben entfernen
			name = this.cleanName(name);
			// doppelte Leerzeichen entfernen
			name = name.replace( /[ ]{2,}/, ' ' );

			let words = name.trim().split( ' ' );

			// falls immer noch zu lang: von hinten nach vorne Wörter nach erstem Buchstaben kürzen
			for ( let i = words.length - 1;  i >= 0; i--) {
				if ( words.join( ' ' ).length > maxChars) {
					words[i] = words[i].replace( /([A-Z])[a-z,ßäöü-]+\.?/, '$1.' );
				} else {
					break;
				}
			}

			// falls immer noch zu lang: Punkte weglassen
			if ( words.join( ' ' ).length > maxChars ) {
				for ( let i = 0, len = words.length;  i < len; i++ ) {
					words[i] = words[i].replace( '.', '' );
				}
			}

			// falls immer noch zu lang: von hinten nach vorne die Leerzeichen weglassen,
			// d.h. die letzten beiden Wörter zusammenkleben
			while ( words.length > 1 && (words.join( ' ' ).length > maxChars) ) {
				words[ words.length - 2] += words[ words.length - 1];
				words.length = words.length - 1;
			}

			name = words.join( ' ' );

		}
		
		return name;
	}

	/**
	 * Hilfsfunktion um Abkürzungsinformationen aus einem Teamnamen zu entfernen
	 *
	 * @param string name
	 */
	private cleanName( name : string ) : string {
		return name.replace( /\{\d\.?|\}/g, '' );
	}
	
}

/** Liste von Teams mit Index = id */
@ArrayOf( () => Team )
@ArrayIndex( 'id' )
export class Teams extends DDindexedArray<Team> {} 


/* Team DB */

@DbType( Team, Teams )
class TeamsDB extends ItemDB<ITeam, Team, Teams> {

	/**
	 * add a single item as json object and get a domain object
	 */
	addObject( json : ITeam ) : Team {
		
		if ( json.foto ) {
			json.foto = fotoDB.addObject( json.foto )
		}
		
		return super.addObject(json);
	}

}

export const teamsDB = new TeamsDB( [ 'foto' ] )
