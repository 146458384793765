import { DDobject, prop, isObject, Indexable } from "ddt/DDmodel";

export interface IDatensatz {

	id				: string
	_md5			: string
	_new			: boolean
	hasValidId		: () => boolean
	[key: string]	: any
}

export function isValidId( id : string ) : boolean {
	return ( ( typeof id == 'string') && !!id.match( /^[a-zA-Z]+[0-9]+$/ ) );
}

export class Datensatz extends DDobject implements IDatensatz {

	@prop()	id		: string
	@prop()	_md5	: string
	@prop()	_new	: boolean

	/**
	 * verhindern, dass ein Datensatz in einen anderen ge-merged wird
	 */
	public mergeProp( this : Indexable, prop : string, value : any, context : any, cid : number) {
			
		let ownValue = this._hidden.props[prop];
		if (ownValue != value) {
			if ( 
					( ownValue instanceof Datensatz ) && 
					isObject(value)
			) {
				// gleicher Datensatz?
				if ( ownValue.id != value.id ) {
					this[prop] = this.getTypedValue( prop, value, context );
				} else {
					ownValue.merge(value, context, cid);
				}
			} else {
				super.mergeProp(prop, value, context, cid);
			}
		}
	}

	/**
	 * Prüft auf gültige Datensatz ID
	 */
	hasValidId() : boolean {
		return isValidId( this.id);
	}

}
