import { ApiProvider, IApiResponse, TApiProcessResponse, IApiLoadOptions, ApiRequestOptions } from "../api";
import { AppStorage } from "../../controllers/storage";

export interface IApiPageResponse extends IApiResponse {
	saison			: number
	menuPath		: string
	meta			: {
		title		: string
		description	: string
	}
	accessError		: number
	stateType?		: string		// set to 'page' by router
	url?			: string		// set by router
	scrollY			: number		// set to window.scrollY by router before page change
	containerHeight	: number		// set to '#content'.height by router before page change
	page?			: unknown		// api v2 page data
}

type TitleFn<T extends IApiPageResponse> = ( json : T ) => string

export interface IPage {
	init() : Promise<void>
	render( parent? : Node ) : Node
	fadeIn() : Promise<void>
}

export interface IPageContext<T extends IApiPageResponse> {
	title		: string | TitleFn<T>
	component	: new( json : T ) => IPage
	readCache	: boolean
	writeCache	: boolean
	processData?: TApiProcessResponse<T>
	getCacheKey?: ( url : string, json : T ) => string
	ssr?		: boolean
	/** url of the page, set by router */
	url?		: string
	/** API version */
	apiVersion?	: number
}

export interface IPageCounter {
	currentVisits	: number
	totalVisits		: number
	version			: string
}

export interface IApiCounterResponse extends IApiResponse {
	counter	: IPageCounter
}

class PageProvider extends ApiProvider {
	
	response : IApiPageResponse
	
	pageUrl( href : string | undefined ) : string {
		
		href = href || location.href;
		
		// remove protocol and domain
		href = href.replace( /^https?:\/\/[^/]+/, '' );
		// remove leading slash
		href = href.replace( /^\//, '' );
		// remove leading pages/
		href = href.replace( /^pages\//, '' );
		
		if ( href == 'index.html' ) {
			// startseite
			href = 'index.php';
		}
		
		// remove anchor
		if ( href.indexOf('#') >= 0 ) {
			href = href.split( '#' )[0];
		}
			
		if ( href.indexOf('?') >= 0 ) {
			// sort params for a consistant cache key
			let [url, query] = href.split( '?' );
			let params = query.split( '&' );
			params.sort( (a, b) => {
				let keyA = a.split( '=' )[0];
				let keyB = b.split( '=' )[0];
				return keyA.localeCompare(keyB);
			})
			href = url + '?' + params.join( '&' );
		}
		
		return 'pages/' + href;// + ( anchor ? '#' + anchor : '' );
	}

	/**
	 * Daten einer Seite via API laden
	 *
	 * returns Promise<IApiPageResponse>
	 */
	private loadPageFromApi( pageCtx : IPageContext<IApiPageResponse>, cachedResponse : IApiPageResponse | null, pageUrl : string ) : Promise<IApiPageResponse> {

		let options : ApiRequestOptions = {};
		if ( cachedResponse ) {
			options.checksum = cachedResponse.checksum
		}
		if ( pageCtx.apiVersion ) {
			options.apiVersion = pageCtx.apiVersion
		}
		
		return this.fetchJson<IApiPageResponse>( this.request(
				pageUrl, null, options
		) )
		.then( ( response ) => {
			
			if ( response.status == 304 ) {
				// nothing changed
				return response;
			}
			if ( !pageCtx.writeCache ) {
				// don't save response in cache
				return response;
			}
			// get cache key
			let cacheKey = pageUrl;
			if ( pageCtx.getCacheKey ) {
				cacheKey = pageCtx.getCacheKey( cacheKey, response )
			}
			// add api version
			response.apiVersion = pageCtx.apiVersion
			// save a clean copy of json data
			return AppStorage.setItem( "rbl-cache", cacheKey, response )
			.then( () => {
				return response
			});
		})
	
	}
	
	/**
	 * returns Promise<IApiPageResponse> 
	 **/
	loadPage( pageCtx : IPageContext<IApiPageResponse>, cachedResponse : IApiPageResponse | null ) : Promise<IApiPageResponse> {
		
		let pageUrl = this.pageUrl( pageCtx.url )
		let res : Promise<IApiPageResponse>
		
		if ( pageCtx.readCache ) {
		
			// lookup in cache
			res = AppStorage.getItem( "rbl-cache", pageUrl )
			.then( ( response : IApiPageResponse ) => {
				if ( response && (!pageCtx.apiVersion || pageCtx.apiVersion == response.apiVersion ) ) {
					response.fromCache = true;
					return response;
				} else {
					return this.loadPageFromApi( pageCtx, null, pageUrl );
				}
			})

		} else {
			res = this.loadPageFromApi( pageCtx, cachedResponse, pageUrl )
		}
		
		return res
		.then( (json) => {
					
			if ( json.status == 200 ) {
				this.response = json;
			}
			
			return json;

		} ) 
		
	}
	
	loadCounter() : Promise<IApiCounterResponse> {
		
		let options : IApiLoadOptions<IApiCounterResponse> =  {
				url				: 'widgets/counter.php',
//				apiVersion		: 2,	
				cacheKey		: 'widgets/counter.php',
				readCache		: true,
				revalidate		: { after : 1000 },
		}

		return this.load( options )
		
	}
	
}


export const pageProvider = new PageProvider;
