
import { IDatensatz, Datensatz } from '../ds';
import { prop, ArrayOf, watch } from 'ddt/DDmodel';
import { DDindexedArray, ArrayIndex } from 'ddt/DDindexedArray';
import { ItemDB, DbType } from '../item-db';

export interface IUser extends IDatensatz {
	name: string;
	vname: string;
	status?: string;
	email?: string;
	username?: string;
}

export class User extends Datensatz {
	
	static status_angemeldet		= 'a';
	static status_nicht_angemeldet	= 'n';
	/** User hat Benutzername/Passwort gewählt, aber Mail noch nicht bestätigt */
	static status_vorlaeufig		= 'v';
	static status_geloescht			= 'c';
	
	@prop()	name		: string;
	@prop()	vname		: string;
	@prop()	email		: string;
	@prop()	status		: string;
	@prop()	username	: string;
	
	
	/**
	 * name first
	 */
	@watch( 'name', 'vname' )
	fullname() : string {
		let name = this.name + ' ' + this.vname;
		return name.trim();
	}
	
	/**
	 * vname first
	 */
	@watch( 'name', 'vname' )
	fullnameV() : string {
		let name = this.vname + ' ' + this.name;
		return name.trim();
	}
	
	@watch( 'status' )
	isAngemeldet() : boolean {
		return this.status == User.status_angemeldet;
	}
	
}

/** Liste von Usern mit Index = id */
@ArrayOf( () => User )
@ArrayIndex( 'id' )
export class Users extends DDindexedArray<User> {} 


/* User DB */

@DbType( User, Users )
class UserDB extends ItemDB<IUser, User, Users> {}

export const userDB = new UserDB( [ 'email', 'email', 'username' ] )
