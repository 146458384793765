
interface IPhpDateLocaleItem {
	shortname	: string
	name		: string
}

interface IPhpDateLocale {
	days	: IPhpDateLocaleItem[]
	months	: IPhpDateLocaleItem[]
}

/**
 * get a two digit representation of a number
 *
 * param	n	Number to convert
 */
export function zeroPad( n : number ) : string {
	return n.toString().padStart(2, '0');
}

export const phpDateLocaleEn : IPhpDateLocale = {
	
	days: [
		{ shortname: "Sun", name: "Sunday"		},
		{ shortname: "Mon", name: "Monday"		},
		{ shortname: "Tue", name: "Tuesday"		},
		{ shortname: "Wed", name: "Wednesday"	},
		{ shortname: "Thu", name: "Thursday"	},
		{ shortname: "Fri", name: "Friday"		},
		{ shortname: "Sat", name: "Saturday"	}
	],

	months: [
		{ shortname: "Jan", name: "January"		},
		{ shortname: "Feb", name: "February"	},
		{ shortname: "Mar", name: "March"		},
		{ shortname: "Apr", name: "April"		},
		{ shortname: "May", name: "May"			},
		{ shortname: "Jun", name: "June"		},
		{ shortname: "Jul", name: "July"		},
		{ shortname: "Aug", name: "August"		},
		{ shortname: "Sep", name: "September"	},
		{ shortname: "Oct", name: "October"		},
		{ shortname: "Nov", name: "November"	},
		{ shortname: "Dec", name: "December"	}
	],

}

export const phpDateLocaleDe : IPhpDateLocale = {
	"days": [
			{ shortname : "So", name : "Sonntag"	},
			{ shortname : "Mo", name : "Montag"		},
			{ shortname : "Di", name : "Dienstag"	},
			{ shortname : "Mi", name : "Mittwoch"	},
			{ shortname : "Do", name : "Donnerstag"	},
			{ shortname : "Fr", name : "Freitag"	},
			{ shortname : "Sa", name : "Samstag"	}
	],
	
	"months": [
			{ shortname : "Jan", name : "Januar"	},
			{ shortname : "Feb", name : "Februar"	},
			{ shortname : "Mär", name : "März"		},
			{ shortname : "Apr", name : "April"		},
			{ shortname : "Mai", name : "Mai"		},
			{ shortname : "Jun", name : "Juni"		},
			{ shortname : "Jul", name : "Juli"		},
			{ shortname : "Aug", name : "August"	},
			{ shortname : "Sep", name : "September"	},
			{ shortname : "Okt", name : "Oktober"	},
			{ shortname : "Nov", name : "November"	},
			{ shortname : "Dez", name : "Dezember"	}
	],
}

let phpDateLocale = phpDateLocaleEn;

export function setPhpDateLocale( locale : IPhpDateLocale ) {
	phpDateLocale = locale;
}

/**
 * formats a date (partially) like PHP date() function
 *
 * j:	Day of the month, without leading zeros 				1 to 31
 *
 * d:	Day of the month, 2 digits with leading zeros 			01 to 31
 *
 * D:	A textual representation of a day, three letters 		Sun through Mon
 *
 * l:	A full textual representation of the day of the week 	Sunday through Saturday
 *
 * m:	Numeric representation of a month, with leading zeros 	01 through 12
 *
 * n:	Numeric representation of a month					 	1 through 12
 *
 * M:	A short textual representation of a month, 3 letters 	Jan through Dec
 *
 * F:	A full textual representation of a month			 	January through December
 *
 * w:	Numeric representation of the day of the week		 	0 (for Sunday) through 6 (for Saturday)
 *
 * Y:	A full numeric representation of a year, 4 digits 		Examples: 1999 or 2003
 *
 * y:	A two digit representation of a year 					Examples: 99 or 03
 *
 * g:	12-hour format of an hour without leading zeros 		1 through 12
 *
 * G:	24-hour format of an hour without leading zeros 		0 through 23
 *
 * h:	12-hour format of an hour with leading zeros 			01 through 12
 *
 * H:	24-hour format of an hour with leading zeros 			00 through 23
 *
 * i: 	Minutes with leading zeros 								00 to 59
 *
 * k: 	Minutes without leading zeros 							0 to 59
 *
 * s: 	Seconds, with leading zeros 							00 through 59
 *
 *
 * param	format	format-string
 * 
 * param	date	date to format
 * 
 * @see {@link http://de3.php.net/manual/en/function.date.php}
 */
export function phpDate( format : string, dateValue? : Date | string | number ) : string {
	
//	let i18n = Languages.languages.global ? Languages.languages.global.i18n : null;

	let date : Date
	if (!dateValue) {
		date = new Date();
	} else if ( dateValue instanceof Date ) {
		date = dateValue;
	} else {
		date = new Date(dateValue);
	}

	// validate Date: http://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
	if ( Object.prototype.toString.call(date) === "[object Date]" ) {
		// it is a date
		if ( isNaN( date.getTime() ) ) {  // d.valueOf() could also work
			// date is not valid
			return '???';
		} else {
			// date is valid
		}
	} else {
		// not a date
		return '???';
	}

	type UsedDateMethods = 
		'getDate' | 'getDate' | 'getDay' | 'getDay' | 'getMonth' | 'getMonth' | 'getMonth' | 'getMonth' | 'getDay'
		| 'getFullYear' | 'getFullYear' | 'getHours' | 'getHours' | 'getHours' | 'getHours' | 'getMinutes'
		| 'getMinutes' | 'getSeconds';
	type ValueConverter = (v: number) => string
	interface DateMarkers { 
		[m:string] : [UsedDateMethods, ValueConverter]
	}
	
	function getName( item : IPhpDateLocaleItem | undefined ) : string {
		return item ? item.name : ''
	}
	
	function getShortname( item : IPhpDateLocaleItem | undefined ) : string {
		return item ? item.shortname : ''
	}
	
	// http://de3.php.net/manual/en/function.date.php
	let dateMarkers : DateMarkers = {
			j:['getDate'	, (v : number) : string => { return v.toString();							}],
			d:['getDate'	, (v : number) : string => { return zeroPad(v);								}],
			D:['getDay'		, (v : number) : string => { return getShortname( phpDateLocale.days[v] )	}],
			l:['getDay'		, (v : number) : string => { return getName( phpDateLocale.days[v] )		}],
			m:['getMonth'	, (v : number) : string => { return zeroPad(v+1);							}],
			n:['getMonth'	, (v : number) : string => { return (v+1).toString();						}],
			M:['getMonth'	, (v : number) : string => { return getShortname( phpDateLocale.months[v] )	}],
			F:['getMonth'	, (v : number) : string => { return getName( phpDateLocale.months[v] )		}],
			w:['getDay'		, (v : number) : string => { return v.toString();							}],
			y:['getFullYear', (v : number) : string => { return zeroPad(v);								}],
			Y:['getFullYear', (v : number) : string => { return v.toString();							}],
			g:['getHours'	, (v : number) : string => { return (((v - 1) % 12) + 1).toString();		}],
			G:['getHours'	, (v : number) : string => { return v.toString();							}],
			h:['getHours'	, (v : number) : string => { return zeroPad( ((v - 1) % 12) + 1);			}],
			H:['getHours'	, (v : number) : string => { return zeroPad(v);								}],
			i:['getMinutes'	, (v : number) : string => { return zeroPad(v);								}],
			k:['getMinutes'	, (v : number) : string => { return v.toString();							}],
			s:['getSeconds'	, (v : number) : string => { return zeroPad(v);								}],
	};

	let dateTxt = format.replace(/[jdDlmnMFwyYgGhHiks]/g, function(m) {

		let dateMarker = dateMarkers[m];
		if ( dateMarker ) {
			let dateMethod = dateMarker[0];
			let rv = date[dateMethod]();
			let converter = dateMarker[1];
			return converter(rv);
		}
		return ''
	});

	return dateTxt;

}
