//import { SwUpdateComponent } from "./sw-update";

import { updateServiceWorker } from '../../app/sw-register';

/** lazy-loading geht nur, wenn Vorgänger vom sw-update Chunk nicht beim build gelöscht wurde!!! */	
export function loadSwUpdate() {
		
	import(/* webpackChunkName: "sw-update" */  './sw-update' )
	.then( (module) => {

		let updateComponent = new module.SwUpdateComponent()
		let parent = document.querySelector( '.header-bar' );
		if ( parent ) {
			updateComponent.render( parent )
		}
	})
	.catch( (reason) => {
		updateServiceWorker()
	})

}
