//import { appRouter } from '../../app/router';
import { TDialogParams } from '../dialog';
	
export function openAlertBox( params : TDialogParams ) : Promise<string> {
		
	return import(/* webpackChunkName: "alert-box" */  './alert-box' )
	.then( (module) => {
			
		return new module.AlertBox( params ).open();

	})
	.catch( (reason) => {
		//return appRouter.alertFailReason(reason);
		alert( 
			params.title.toUpperCase() + '\n\n' +
			params.text.replace( /<br>/g, '\n' )
		)
		return reason
	})

}