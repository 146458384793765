
/**
 * class for error messages from api calls
 *
 * usage:
 *		if ( !response.xhrValue ) {
 *			return Promise.reject( new ApiError( response.xhrMessages ) );
 *		}
 */

export class ApiError {
	
	constructor( public messages? : string[] ) {
		
	}
	
}
