/**
 * setzt die Klasse "sticky-header", wenn der Header sticky wird
 */

// Get the page-wrapper
let parent = document.body;// document.getElementById("ip_page_wrapper");

// Get the sticky-trigger element
let contentTop = document.getElementById( 'sticky-trigger' );

export function stickyObserver() : IntersectionObserver {
	// IntersectionObserver
	
	return new IntersectionObserver( function(entries) {
		// no intersection with screen
		if ( entries[0].intersectionRatio === 0 ) {
			parent.classList.add("sticky-header");
		} 
		// fully intersects with screen
		else if( entries[0].intersectionRatio === 1 ) {
			parent.classList.remove("sticky-header");
		}
	}, {
		threshold: [0,1],
//		root: pageWrapper,
		rootMargin: '0px 0px 0px 0px',
	});
	
}

if ( ('IntersectionObserver' in window) && (contentTop instanceof Element) ) {
	
	let observer = stickyObserver();

	observer.observe( contentTop );

}
