import { DDobject, Indexable } from "ddt/DDmodel";
import { API_KEY } from './api-key';
import { serviceUrl } from "./api-service";
import { siteuser } from "./user/siteuser";
import { AppStorage } from "../controllers/storage";
import { delay } from "../utils/delay-promise";
import { log, Logger, recentFetch } from "../app/app-log";


declare const APP_BUILD : boolean


export interface IApiResponse {
	error?		: string
	debugGroup?	: string
	debug?		: string[]
	status?		: number
	checksum	: string
	timestamp	: number
	xhrValue?	: any
	xhrMessages?: string[]
	fromCache?	: boolean
	apiVersion?	: number
}

export interface ApiRequestOptions extends RequestInit, Indexable {
	/** if set,  */
	checksum?	: string
	headers?	: Indexable
	/** API version */
	apiVersion?	: number
}

/** Function, that converts response into domain model and store objects in memory db */
export type TApiProcessResponse<T extends IApiResponse> = ( json : T ) => void
/** Function, called when revalidated response has changed */
export type TApiUpdateCallback<T extends IApiResponse> = ( json : T ) => void

export interface IApiRevalidateOptions {
	/** delay of revalidation in milliseconds */
	after	: number
}

export interface IApiLoadOptions<T extends IApiResponse> {
	/** API endpoint */
	url				: string
	/** API version */
	apiVersion?		: number
	/** endpoint get params */
	params?			: { [name : string] : string | number }
	/** DB index */
	cacheKey		: string
	/** Function, that converts the result */
	processData?	: TApiProcessResponse<T>
	/** try to read response from cache first? (default=true) */
	readCache?		: boolean
	/** write response to cache? (default=true) */
	writeCache?		: boolean
	/** should cached response be revalidated: 0 = no; >0 = in x milliseconds */
	revalidate?		: IApiRevalidateOptions
	/** callback function, called when revalidated response has changed */
	updateCallback?	: TApiUpdateCallback<T>
	/** read and write response from/to memory cache? */
	memoryCache?	: boolean
}

export class ApiProvider extends DDobject {
	
	protected cache		: Map<string, IApiResponse> = new Map
		
	/**
	 * create request for fetch()
	 * 
	 * param url
	 * 
	 * param params		: { key : value }
	 * 
	 * param options
	 * 
	 * 	->	checksum: string // from previous request
	 * 
	 * 	->	method: "POST", // *GET, POST, PUT, DELETE, etc.
	 * 
	 * 	->	mode: "cors", // no-cors, cors, *same-origin
	 * 
	 * 	->	cache: "no-cache", // *default, no-store, no-cache, reload, force-cache, only-if-cached
	 * 
	 * 	->	credentials: "same-origin", // include, same-origin, *omit
	 * 
	 * 	->	headers: {
	 * 			"Content-Type": "application/json; charset=utf-8",
	 * 			"Content-Type": "application/x-www-form-urlencoded",
	 * 		},
	 * 
	 * 	->	redirect: "follow", // manual, *follow, error
	 * 
	 * 	->	referrer: "no-referrer", // no-referrer, *client
	 * 
	 * 	->	body: data, // body data type must match "Content-Type" header
	 * 
	 */
	request( url : string, params? : Indexable | null, options? : ApiRequestOptions) : Request {
		let paramStr = '';

		let paramPairs : string[] = [];
		if ( params ) {
			Object.keys(params).forEach( (key) => {
				let value = params[key];
				if ( typeof value !== 'undefined' ) {
					paramPairs.push( key + '=' + encodeURIComponent(value) );
				}
			})
		}
						
//		if ( ApiProvider.sessionId ) {
//			paramPairs.push( 'PHPSESSID=' + ApiProvider.sessionId )
//		}

		if ( paramPairs.length ) {
			paramStr = '?' + paramPairs.join( '&' );
		}
		
		let credentials : RequestCredentials;
		if ( APP_BUILD ) {
			credentials = 'omit';
		} else {
			credentials = 'include';
		}
		
		let init : RequestInit & Indexable & { headers : Indexable } = {
			cache		: 'no-store',
			mode		: 'cors',
			credentials	: credentials,
			headers		: {
				'x-api-key': API_KEY,
			}
		}
		
		if ( siteuser.authToken ) {
			init.headers['x-auth-token'] = siteuser.authToken;
		}
		
		let vapi = 'vapi'
		if ( options ) {
			for ( const key in options ) {
				if ( key == 'checksum' ) {
					init.headers['x-api-checksum'] = options[key];
				} else if ( key == 'apiVersion' ) {
					if ( options.apiVersion ) {
						vapi = 'v' + options.apiVersion + 'api';
					}
				} else if ( key == 'headers' && options.headers && init.headers ) {
					for ( const header in options.headers ) {
						init[key][header] = options.headers[header];
					}
				} else {
					init[key] = options[key];
				}
			}
		}
		
		url = serviceUrl + '/' + vapi + '/' + url.replace( /^\//, '' );
		
		recentFetch.url		= url;
		recentFetch.options = init;
		recentFetch.timestamp = new Date().toLocaleString();
		
		return new Request(
			url + paramStr,
			init
		);

	}
	
	/**
	 * fetch a JSON object from server
	 * 
	 * param request	prepared by this.request()
	 * 
	 */
	fetchJson<T extends IApiResponse>( request : Request ) : Promise<T> {
		
		return window.fetch( request )
		.then( (response) => {
			
			let authToken = response.headers.get( 'x-auth-token' );
			if ( authToken ) {
				console.log( 'authToken: ',  authToken );
				siteuser.authToken = authToken;
			}
			
			if ( response.status == 200 ) {
				return response.json();
			} else {
				return Promise.reject( 'fetch status: ' + response.status + ': ' + response.statusText )
			}
			
		})
		.then( (response : T) => {
			
			// debug
			if ( response.debug ) {
				if ( console.group && response.debugGroup ) {
					console.group( response.debugGroup )
				}
				response.debug.forEach( (line) => {
					console.log( line )
				})
				if ( console.groupEnd && response.debugGroup ) {
					console.groupEnd()
				}
			}
			
			return response
		})
		.catch( (reason) => {
			
			log( reason, {
				url	: request.url,
				method	: request.method,
			}, Logger.ERROR )
			
			return Promise.reject(reason)
		})

	}
	
	protected load<T extends IApiResponse>( options : IApiLoadOptions<T> ) : Promise<T> {
		
		// defaults
		if ( typeof options.readCache == 'undefined' ) {
			options.readCache = true;
		}
		if ( typeof options.writeCache == 'undefined' ) {
			options.writeCache = true;
		}

		// lookup in cache
		let res : Promise<T> | undefined
		
		if ( options.readCache ) {
			if ( options.memoryCache ) {
				let response = this.cache.get( options.cacheKey ) as T | undefined
				if ( response ) {
					response.fromCache = true;
					this.revalidate( options, response );
					res = Promise.resolve( response )
				}
			}
			
			if ( !res ) {
				res = AppStorage.getItem( "rbl-cache", options.cacheKey )
				.then( ( response : T ) => {
					
					if ( response && (!options.apiVersion || options.apiVersion == response.apiVersion ) ) {
						if ( options.memoryCache ) {
							this.cache.set( options.cacheKey, response );
						}
						
						response.fromCache = true;
						this.revalidate( options, response );
						return response;
					} else {
						return this.loadFromApi( options );
					}
					
				})
			}
		} else {
			res = this.loadFromApi( options );
		}
		
		return res.then( ( response ) => {

			if ( options.processData ) {
				options.processData( response );
			}
			
			return response;
		})
		
	}
	
	revalidate<T extends IApiResponse>( options : IApiLoadOptions<T>, cachedResponse : T ) : Promise<T> {
		
		if ( options.revalidate ) {
			// look for updates, in 'revalidate' milliseconds
			return delay( options.revalidate.after )
			.then( () => {
				return this.loadFromApi( options, cachedResponse )
			})
			.then( (update) => {
				// something changed?
				if ( update.status == 200 ) {
					if ( options.processData ) {
						options.processData( update );
					}
					if ( options.updateCallback ) {
						options.updateCallback( update );
					}
				}
				
				return update;
			})
		}
		
		return Promise.resolve( cachedResponse );
	}

	/**
	 * Turnierteams abrufen
	 *
	 * returns Promise<IApiChronikResponse>
	 */
	protected loadFromApi<T extends IApiResponse>( options : IApiLoadOptions<T>, cachedResponse? : T ) : Promise<T> {
		
		let requestOptions : ApiRequestOptions = {};
		if ( cachedResponse ) {
			requestOptions.checksum = cachedResponse.checksum
		}
		if ( options.apiVersion ) {
			requestOptions.apiVersion = options.apiVersion
		}
			
		return this.fetchJson( this.request(
				options.url,
				options.params,
				requestOptions
		) )
		.then( ( response ) => {
			if ( response.status == 304 ) {
				// nothing changed
				return response as T;
			}
			
			if ( !options.writeCache ) {
				return response as T
			}

			if ( options.memoryCache ) {
				// store a copy of json data in memory
				this.cache.set( options.cacheKey, response );
			}

			// add api version
			response.apiVersion = options.apiVersion
			// store a copy of json data in database
			return AppStorage.setItem( "rbl-cache", options.cacheKey, response )
			.then( () => {
				return response as T
			});
		})
		
	}
	
}
