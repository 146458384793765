
import { Indexable } from 'ddt/DDmodel';
import * as ErrorStackParser from 'error-stack-parser';
import { API_KEY } from '../api/api-key';
import { serviceUrl } from "../api/api-service";

declare const APP_BUILD : boolean
declare const APP_REVISION : number

//console.log( 'Revision:', APP_REVISION )

export const Logger = {
	DEBUG	: 100,
	ERROR	: 400,
}

const LogLevel = Logger.DEBUG

interface ILogObject {
	timestamp	: string
	level		: number
	name		: string
	message		: string
	agent		: string
	app			: boolean
	rev			: number
	data?		: any
	stack?		: StackFrame[]
}

interface IRecentFetch { 
	url			: string,
	options		: any,
	timestamp	: string,
	now			: string,
}

export const recentFetch : IRecentFetch = {
	url			: '',
	options		: undefined,
	timestamp	: '',
	now			: '',
}

function sendLog( log : ILogObject ) : Promise<Response> {
		
	let credentials : RequestCredentials;
	if ( !APP_BUILD ) {
		credentials = 'include';
	} else {
		credentials = 'omit';
	}
	
	let init : RequestInit & Indexable & { headers : Indexable } = {
		method		: 'POST',
		body		: JSON.stringify( log ),
		cache		: 'no-store',
		mode		: 'cors',
		credentials	: credentials,
		headers		: {
			'x-api-key': API_KEY,
		}
	}
	
	let url = serviceUrl + '/vapi/log.php';
	
	return window.fetch( new Request(
		url,
		init,
	) );

}

/**
 * filter logging
 * 
 * @returns boolean		prevent logging?
 */
function inhibitLog( msg : string ) : boolean {
		
//		A WebKit belső hibát észlelt (HU)
//		WebKit hat einen internen Fehler festgestellt

//		A hálózati kapcsolat megszakadt (HU)
//		Die Netzwerkverbindung wurde unterbrochen

	if ( msg ) {
		if ( msg.match( /failed to fetch/i ) ) {
			// ignore (chrome)
			return true;
		}
		//										  FR     EN        BS Bosn.  ES        TR         RO     HU
		if ( msg.match( /unterbrochen|abgebrochen|annulé|cancelled|poništeno|cancelado|vazgeçildi|anulat|visszavonva/i ) ) {
			// ignore (Safari)
			return true;
		}
		if ( msg.match( /was aborted/i ) ) {
			// ignore (Firefox)
			return true;
		}
		//																HU
		if ( msg.match( /Zeitüberschreitung|load failed|request timed out|Nem tudtam csatlakozni a szerverre/i ) ) {
			// ignore (Safari)
			return true;
		}
		if ( msg.match( /NetworkError when attempting to fetch resource/i ) ) {
			// ignore (Firefox)
			return true;
		}
		if ( msg.match( /fetch status: 5(0[24]|53)/i ) ) {
			// ignore http status 502 bad gateway, 504 gateway timeout
			// 553 unknown status (applebot)
			return true;
		}
		if ( msg.match( /fetch status: 403/i ) && window.navigator.userAgent.match( /bing/i ) ) {
			// ignore BingBot/BingPreview error fetching siteuser. (propably missing api key)
			return true;
		}
		if ( msg.match( /undefined is not an object \(evaluating 'a\.[NL]'/ ) ) {
			// error, that is not in our code. Chrome for iOS Plugin???
			return true;
		}
		if ( msg.match( /this._logicalFormForControl\(e\)/ ) ) {
			// error, that is not in our code. Safari form controls?
			return true;
		}
	}
	
	return false
}

export function log( msg : string | Error, data? : any, level : number = Logger.DEBUG ) {
	let log : ILogObject | undefined
	
	if ( msg instanceof Error ) {

		if ( inhibitLog(msg.message) ) {
			// uninteressante Logs nicht senden
			return;
		}
		
		let stack	: StackFrame[] | undefined 
		let parseFailMsg	= ''
		try {
			stack = ErrorStackParser.parse( msg )
		} catch (e) {
			parseFailMsg  = ' (ErrorStackParser failed'
			if ( e instanceof Error ) {
				parseFailMsg += ': "' + e.message + '")'
			}
			parseFailMsg += ')'
		}	
			
		log = {
			timestamp	: new Date().toLocaleString(),
			level		: Logger.ERROR,
			name		: msg.name,
			message		: msg.message + parseFailMsg,
			app			: APP_BUILD,
			rev			: APP_REVISION,
			agent		: window.navigator.userAgent,
			stack		: stack,
		}
		if ( data ) {
			log.data = data
		}
			
		console.error( log )
		
	} else if ( level >= LogLevel ) {
		
		if ( typeof msg != 'string' ) {
			msg = JSON.stringify( msg )
		}

		if ( inhibitLog(msg) ) {
			// uninteressante Logs nicht senden
			return;
		}
		
		let stack : StackFrame[] | undefined
		if ( level >= Logger.ERROR ) {
			// Trace erzwingen?
			try {
				throw new Error( 'USER_ERROR: ' + msg );
			} catch ( e ) {
				stack = ErrorStackParser.parse( e as Error );
			}
		}
		
		log = {
			timestamp	: new Date().toLocaleString(),
			level		: level,
			name		: 'log',
			app			: APP_BUILD,
			rev			: APP_REVISION,
			agent		: window.navigator.userAgent,
			message		: msg,
		}
		
		if ( data ) {
			log.data = data
		}
		
		if ( stack ) {
			log.stack = stack
		}
		
		console.log( msg )
	}
	
	if ( log ) {
		// post log
		sendLog( log )
	}
}

// Listen to uncaught errors
window.addEventListener( 'error', ( ev : ErrorEvent ) => {
	log( ev.error );
})

// Listen to uncaught promises rejections
// nutzlos
//window.addEventListener( 'unhandledrejection' , (ev) => {
//	// Trace erzwingen?
//	try {
//		throw new Error( 'unhandledrejection: ' + ev.reason );
//	} catch ( e ) {
//		log( e );
//	}
//} );
