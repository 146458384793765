import { ArrayOf, prop } from "ddt/DDmodel";
import { DDindexedArray, ArrayIndex } from "ddt/DDindexedArray";
import { Datensatz, IDatensatz } from "../ds";
import { pageProvider, IApiPageResponse } from "./page-provider";
import { ItemDB, DbType } from "../item-db";

export interface IMenuItem extends IDatensatz {
	typ				: string
	parentid		: string
	caption			: string
	hint			: string
	link			: string
	content_param	: string
	passthrough		: string
	pos				: number
	hasSub			: boolean
	menuitems?		: IMenuItem[] | MenuItems
}

export class MenuItem extends Datensatz {
	static typ_category	= 'k'
	static typ_item		= 'i'

	@prop()	typ				: string
	@prop()	parentid		: string
	@prop()	caption			: string
	@prop()	hint			: string
	@prop()	link			: string
	@prop()	content_param	: string
	@prop()	passthrough		: string
	@prop()	pos				: number
	@prop()	hasSub			: boolean

	/** if this is a category, menuitems contains all item of the category */
	@prop( () => MenuItems )
			menuitems		: MenuItems
			
	@prop()	selected		: boolean
			
	@prop()	expanded		: boolean = true

	isLink() : boolean {
		return this.typ == MenuItem.typ_item;
	}
	
	href() : string {
		
		let href = this.link;
		if ( this.passthrough && pageProvider.response ) {
			// add dynamic params
			let params = this.passthrough.split( ',' );
			let pageData = pageProvider.response;
			params.forEach( (param) => {
				let value = pageData[param as keyof IApiPageResponse];
				if ( value ) {
					href += ( href.indexOf( '?') >= 0 ) ? '&' : '?';
					href += param + '=' + value;
				}
			})
		}
		
		return href;
	}
	
}

@ArrayOf( () => MenuItem )
@ArrayIndex( 'id' )
export class MenuItems extends DDindexedArray<MenuItem> {
	
	/**
	 * set one item as selected and unselect all other items
	 */
	select( id : string ) {
				
		this.forEach( (item) => {

			item.selected = ( item.id === id )
		})
	}
	
} 


/* MenuItem DB */

@DbType( MenuItem, MenuItems )
class MenuItemDB extends ItemDB<IMenuItem, MenuItem, MenuItems> {}

export const menuitemDB = new MenuItemDB( ['menuitems'] )
