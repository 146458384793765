import { DDindexedArray } from "ddt/DDindexedArray";
import { Datensatz, IDatensatz } from "./ds";


export function DbType<T extends Datensatz, A extends DDindexedArray<T>>( itemType : { new() : T } , arrayType : { new() : A } ) {

	return ( cTor: { prototype: any; } ) => {
		Object.defineProperty(cTor.prototype, '__itemType', {
			// make it invisible in own properties
			enumerable: false,
			// make it undeletable
			configurable: false,
			value: itemType,
		});
		Object.defineProperty(cTor.prototype, '__arrayType', {
			// make it invisible in own properties
			enumerable: false,
			// make it undeletable
			configurable: false,
			value: arrayType,
		});
	};
	
}

export abstract class ItemDB<I extends IDatensatz, T extends Datensatz, A extends DDindexedArray<T>> {
	
	db				: A
	optionalFields	: string[] | null

    __itemType?		: new( j : I ) => T
    __arrayType?	: new( j : I[] | undefined ) => A

	constructor( optionalFields : string[] | null = null ) {
		this.db = this.createItems();
		this.optionalFields = optionalFields;
//		this.db.__assoc
	}

    item( id : string | number | undefined ) : T | undefined {
		if ( typeof id === 'undefined' ) return id;
		return this.db.item( id as string );
    }
    
	createItem( json : I ) : T {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return new this.__itemType!( json );
	}
	
	createItems( json? : I[] ) : A {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return new this.__arrayType!( json );
	}
	
	/**
	 * only insert items with a valid id into DB.
	 * should be overridden, if DB-index isn't 'id'
	 * 
	 * @param item
	 */
	itemHasValidIindex( item : T ) : boolean {
		return item.hasValidId();
	}
	
	/**
	 * get the key of a json item
	 * should be overridden, if DB-index is a function
	 * 
	 * @param json
	 */
	getKey( json : I ) : string {
		return json[this.db.__assoc as string];
	}

	/**
	 * add a single item as json object and get a domain object
	 */
	addObject( json : I ) : T {

		let key = this.getKey(json);
		if ( key === undefined ) {
			throw new Error( 'ItemDB.addObject: property of index key ' + (this.db.__assoc as string) + ' not found!' )
		}

		const item = this.db.item( key )
		if ( item ) {
			// existing item
			if ( json._md5 && item._md5 != json._md5 ) {
				item.merge( json );
			} else if ( this.optionalFields ) {
				this.optionalFields.forEach( (key) => {
					if ( typeof json[key] != 'undefined' ) {
						item.mergeProp( key, json[key], null, 0 )
					}
				})
			}
		} else {
			// new item
			const item = this.createItem( json )
			if ( this.itemHasValidIindex(item) ) {
				this.db.push(item)
			}
			
			return item;
		}
	
		return item;
	}

	/**
	 * add a list of items as json array and get an array of domain objects
	 */
	addArray( json : Array<I|T> | A ) : Array<T> {

		if ( json ) {
			(json as I[]).forEach( (obj, i) => {
				let item = this.addObject( obj );
				if ( item ) {
					json[i] = item;
				}
			})
			
			return json as Array<T>
		}
		return []
	}

	
}
