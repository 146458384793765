import { ArrayOf, prop, watch } from "ddt/DDmodel";
import { DDindexedArray, ArrayIndex } from "ddt/DDindexedArray";
import { IDatensatz, Datensatz } from "../ds";
import { ItemDB, DbType } from "../item-db";
import { serviceUrl } from "../api-service";

export interface IFoto extends IDatensatz {
	gruppe	: string
	version	: number
	filename: string
	width	: number
	height	: number
}

export class Foto extends Datensatz implements IFoto {

	@prop()	gruppe			: string
	@prop()	version			: number
	@prop()	filename		: string
	@prop()	width			: number
	@prop()	height			: number

	@prop()	selected		: boolean

	/**
	 * gibt die URL der Datei zurück
	 *
	 * @return string
	 */
	@watch( 'version' )
	url() : string {
		let path = serviceUrl + '/pictures/' + this.id + '/' + this.gruppe + '/v' + this.version + '/';

		path += encodeURIComponent( this.filename );

		return path;
	}

	@watch( 'version' )
	thumbUrl() {
		let path = serviceUrl + '/thumbnails/' + this.id + '/' + this.gruppe + '/v' + this.version + '/';

		path += encodeURIComponent( this.filename );

		return path;
	}
}

/** Liste von Fotos mit Index = id */
@ArrayOf( () => Foto )
@ArrayIndex( 'id' )
export class Fotos extends DDindexedArray<Foto> {} 


/* Foto DB */

@DbType( Foto, Fotos )
class FotoDB extends ItemDB<IFoto, Foto, Fotos> {}

export const fotoDB = new FotoDB
