import { AppStorage } from "../../controllers/storage";
import { ApiProvider, IApiResponse } from "../api";
import { userDB, IUser } from "../user/user";
import { IForm } from "../form";
import { UserSettingsForm } from "./form-settings";
import { LoginForm } from "./form-login";
import { ISiteuser, siteuser } from "./siteuser";
import { DseForm } from "./form-dse";
import { ContactForm } from "./form-contact";
import { RegisterForm } from "./form-register";
import { ProfileForm } from "./form-profile";
import { teamsDB } from "../team/teams";
import { PwRemindForm } from "./form-pw-remind";
import { PwResetForm } from "./form-pw-reset";
import { menuDB } from "../cms/menu";
//import { log } from "../../app/app-log";

interface IApiSiteuserResponse extends IApiResponse {
	siteuser	: ISiteuser
}

export interface IApiLoginFormResponse extends IApiResponse {
	loginForm		: IForm
	dseForm			: IForm
	siteuser		: ISiteuser
}

interface IApiPasswordResetResponse extends IApiResponse {
	resetForm?		: IForm
	remindForm?		: IForm
	passwordStatus	: number
	sendTo?			: string
}

export interface IApiSettingsFormResponse extends IApiResponse {
	form		: IForm | UserSettingsForm
}

export interface IApiRegisterFormResponse extends IApiResponse {
	siteuser			: ISiteuser
	userRegisterStatus	: number
	form				: IForm | RegisterForm
}

export interface IApiProfileFormResponse extends IApiResponse {
	user				: IUser
	form				: IForm | ProfileForm
}

interface IApiContactFormResponse extends IApiResponse {
	modulStatus		: number
	recipient		: string
	form			: IForm | ContactForm
	notificationText: string
}

export interface IConfigDocuments {
	consent		: string
	contract	: string
	rules		: string
}

//Login Status
export const ERROR_FORBIDDEN			=  0;
export const ERROR_NOT_LOGGED_IN		=  1;
export const LOGIN_OK					=  2;
export const ERROR_EMAIL_INVALID		=  4;
export const ERROR_DSE_NOT_ACCEPTED		=  8;

/**
 * 
 */
export class UserProvider extends ApiProvider {

	private updateSiteuser( json : IApiSiteuserResponse ) {
		if ( json.siteuser ) {
			if ( json.siteuser.user ) {
				json.siteuser.user = userDB.addObject( json.siteuser.user )
			}
			if ( json.siteuser.teams ) {
				teamsDB.addArray( json.siteuser.teams )
			}
			siteuser.merge( json.siteuser );
			
			if ( siteuser.teams.length ) {
				// set favourite team
				let team = siteuser.teams.item( siteuser.teamid );
				if ( team ) {
					siteuser.team = team
				} else {
					// set default favourite team
					siteuser.team = siteuser.teams[0];
				}
			} else {
				siteuser.team = null;
			}

			json.siteuser = siteuser
			//console.log( 'updateSiteuser', 'siteuser.team:', siteuser.team );
		}

	}
	
	loadSiteuser() : Promise<IApiSiteuserResponse> {
		
		return this.load({
			url			: 'user/siteuser.php',
			cacheKey	: 'user/siteuser',
			revalidate	: { after : 500 },
			processData : this.updateSiteuser,
		})
	}
	
	refreshSiteuser( cachedResponse? : IApiSiteuserResponse ) : Promise<IApiSiteuserResponse> {
		
		return this.load({
			url			: 'user/siteuser.php',
			cacheKey	: 'user/siteuser',
			processData : this.updateSiteuser,
			readCache	: false,
		})
		.then( (data) => {
			if ( data.status == 200 ) {
				// something changed
//				log( 'Siteuser refresh', {
//					userid		: data.siteuser?.user?.id,
//					loginStatus	: data.siteuser?.loginStatus,
//				} )
			}
			
			return data
		})
		
	}

	/**
	 * App login
	 *
	 * returns Promise<IApiLoginFormResponse>
	 */
	login( form? : LoginForm ) : Promise<IApiLoginFormResponse> {
			
		return this.fetchJson<IApiLoginFormResponse>( this.request(
				'user/login.php',
				{
					action: 'jkcms_authenticate',
				},
				{
					method	: 'POST',
					headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body	: form ? form.postBody() : '',
				}

		) )
		.then( ( json ) => {
			
			// clear menu memory database (it prevents updating menus)
			menuDB.db.length = 0;
			
			this.updateSiteuser(json);
			
			if ( json.loginForm ) {
				if ( form ) {
					form.merge( json.loginForm )
				}
			}
			
			return json;
		})
		
	}

	/**
	 * Datenschutzerklärung akzeptieren
	 *
	 * returns Promise<IApiLoginFormResponse>
	 */
	acceptDse( form : DseForm ) : Promise<IApiLoginFormResponse> {
			
		return this.fetchJson<IApiLoginFormResponse>( this.request(
				'user/login.php',
				{
					action: 'accept_dse',
				},
				{
					method	: 'POST',
					headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body	: form.postBody(),
				}

		) )
		.then( ( json ) => {
			
			this.updateSiteuser(json);
			
			if ( json.dseForm ) {
				form.merge( json.dseForm )
			}
			
			return json;
		})
		
	}

	/**
	 * logout
	 *
	 * returns Promise<void>
	 */
	logout() : Promise<void> {

		return this.fetchJson( this.request(
				'user/login.php',
				{
					action: 'jkcms_logout',
				}
		) )
		.then( ( json : IApiResponse ) => {

			siteuser.reset();
			
			// clear AppStorage
			// @TODO clear only data with personalized data???
			return AppStorage.clear( "rbl-cache" );
		})
		
	}

	/**
	 * Registrierung/Mailcheck Formular absenden
	 *
	 * returns Promise<IApiRegisterFormResponse>
	 */
	register( form : RegisterForm ) : Promise<IApiRegisterFormResponse> {
			
		return this.fetchJson<IApiRegisterFormResponse>( this.request(
				'pages/user/register.php',
				undefined,
				{
					method	: 'POST',
					headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body	: form.postBody(true),
				}

		) )
		.then( ( json ) => {
			
			if ( json.siteuser ) {
				if ( json.siteuser.user ) {
					json.siteuser.user = userDB.addObject( json.siteuser.user )
				}
				siteuser.merge( json.siteuser );
				json.siteuser = siteuser
			}
			
			if ( json.form ) {
				form.merge( json.form )
			}
			
			return json;
		})
		
	}

	/**
	 * Password reset
	 *
	 * returns Promise<IApiPasswordResetResponse>
	 */
	submitPwReset( form : PwRemindForm | PwResetForm ) : Promise<IApiPasswordResetResponse> {
			
		return this.fetchJson<IApiPasswordResetResponse>( this.request(
				'pages/user/user_remind.php',
				undefined,
				{
					method	: 'POST',
					headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body	: form.postBody( true ),
				}

		) )
		
	}

	/**
	 * Registrierung/Mailcheck Formular absenden
	 *
	 * returns Promise<IApiRegisterFormResponse>
	 */
	submitUserProfile( form : ProfileForm ) : Promise<IApiProfileFormResponse> {
			
		return this.fetchJson<IApiProfileFormResponse>( this.request(
				'user/profile.php',
				{
					action: 'save',
				},
				{
					method	: 'POST',
					headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body	: form.postBody(true),
				}

		) )
		.then( ( json ) => {
			
			if ( json.user ) {
				userDB.addObject( json.user )
			}
			
			if ( json.form ) {
				form.merge( json.form )
			}
			
			return json;
		})
		
	}
	
	submitContactForm( form : ContactForm ) : Promise<IApiContactFormResponse> {
		
		return this.fetchJson<IApiContactFormResponse>( this.request(
				'user/kontakt.php',
				null,
				{
					method	: 'POST',
					headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body	: form.postBody( true ),
				}
		) )
		.then( ( response ) => {
			
			if ( response.form ) {
				form.merge( response.form )
			}
			
			return response;
		})
		
	}

	/**
	 * Einstellung speichern
	 *
	 * param	name	Name/Typ der Einstellung
	 * returns Promise<IApiResponse>
	 */
	setSetting( name : string, value : string | number ) : Promise<IApiSettingsFormResponse> {
		
		return this.fetchJson<IApiSettingsFormResponse>( this.request(
				'user/setting.php',
				{
					action: 'set',
					name:	name,
					value:	value,
				}
		) )
		.then( ( json ) => {
			
			if ( json.xhrValue ) {
				return json 
			} else {
				return Promise.reject( json.xhrMessages );
			}
			
		})
		
	}

}

let provider : UserProvider;
export function userProvider() : UserProvider {
	if ( !provider ) provider = new UserProvider;
	return provider;
}
