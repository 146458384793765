import { Page } from "../components/page/page";
import { IApiPageResponse, pageProvider, IPageContext } from "../api/cms/page-provider";
import { pageDispatcher, PageDispatchError, getPageKey } from "./page-dispatcher";
import { setContentTitle } from "./set-content-title";
import { LoadingSpinner } from "../components/loading-spinner/loading-spinner";
import { TDialogParams } from "../components/dialog";
import { AppStorage } from "../controllers/storage";
import { externalRoute } from "./external-route-web";
import { updateServiceWorker } from "./sw-register";
import { ApiError } from "../api/api-error";
import { log } from "./app-log";
import { openAlertBox } from "../components/alert-box/alert-box-loader";

declare const APP_BUILD : boolean

type THistoryStateAction = 'none' | 'push' | 'replace';

export interface IPopupHistoryItem {
	id		: number
	name	: string
	close?	: ( item? : IPopupHistoryItem ) => boolean | void
	remove	: boolean
	// if set, resolve will be called, when item is removed from PopupHistory
	resolve?: () => void
}

export class Router {
	
	/** current page */
	page : Page
	
	/** start page set by web-app */
	startPage		: string

	popupHistory	: IPopupHistoryItem[] = []
	
	/** contains the page url for the loop: navigateTo() -> close popups -> onStateChange -> navigateTo */
	nextPageUrl		: string
	
	/** url of current page */
	currentPage		: string | undefined
	
	updateWaiting	: boolean
	
	/** PopupHistoryItems, die über einen Seitenwechsel erhalten bleiben sollen */
	private popupHistoryKeeps : IPopupHistoryItem[] = [] 
	
	constructor() {
		
		window.addEventListener( 'popstate', this.popStateHandler.bind(this) )
	}
	
	private getPageUrl( url : string ) : Promise<string> {
		
		if ( APP_BUILD ) {
			if ( getPageKey( url ) == 'index' ) {
				url = this.startPage
				
				return AppStorage.getItem( 'app-settings', 'current_page' )
				.then( (current_page) => {
					if ( current_page ) {
						return current_page.url || url;
					}
					return url;
				})
			}
		}
		
		return Promise.resolve( url )
	}
	
	forceLoadPage( url : string ) {
		
		if ( APP_BUILD ) {
			AppStorage.setItem( 'app-settings', 'current_page', { url : url } )
			.then( () => {
				window.location.reload();
			})
		}
		
		if ( !APP_BUILD ) {
			window.location.href = url;
		}
	}
	
	/** 
	 * initial page load (called from app entry)
	 **/
	loadPage( url : string ) : Promise<IApiPageResponse> {
		let pageCtx	: IPageContext<IApiPageResponse>
		let json : IApiPageResponse
		
		return this.getPageUrl( url )
		.then( (pageUrl) => {
			url = pageUrl;
			return pageDispatcher( url )
		} )
		.then( (module) => {
			
			pageCtx = module.getPage()
			pageCtx.url = url;
			
			// ssr
			let dataEl = document.getElementById( 'page-data' )
			if ( dataEl && dataEl.innerText ) {
				let json : IApiPageResponse = JSON.parse( dataEl.innerText )
//				requireUpdate = false
				dataEl.remove()
				if ( pageCtx.writeCache ) {
					let cacheKey = pageProvider.pageUrl( url )
					AppStorage.setItem( "rbl-cache", cacheKey, json );
				}
				return json;
			}

			return pageProvider.loadPage( pageCtx, null )
		})
		.then ( (data) => {
			json = data;

			if ( pageCtx.processData ) {
				pageCtx.processData(json)
			}
			
			// set title
			this.setContentTitle( pageCtx, json );

			// create page
			this.page = new pageCtx.component( json ) as Page;
			// init page
			return this.page.init()
		})
		.then( () => {
			// render page
			this.page.render();

			this.setCurrentPage( pageCtx, 'replace' );
			
			// revalidate data
			this.revalidatePage( pageCtx, json );

			return json;
		})
				

	}
	
	/**
	 * single page app page change
	 */
	navigateTo( url : string | null | undefined ) {
		
		if ( url === null || typeof url === 'undefined' ) return;
		
		if ( this.updateWaiting ) {
			updateServiceWorker( url );
			return;
		}
		
		if ( this.popupHistory.length ) {
			// save target url
			this.nextPageUrl = url;
			// first close open popups
			let len = this.popupHistory.length;
			for ( let i = 0; i < len; i++ ) {
				// pop element
				let item = this.popupHistory.pop();
				if ( item && item.close ) {
					let keep = item.close( item )
					if ( keep ) {
						// item (menu?) should stay on top
						this.popupHistoryKeeps.push( item )
					}
				}
			}
			// remove from history => continues with popStateHandler
			window.history.go( -len );
			return;
		}

		if ('scrollRestoration' in history) {
			history.scrollRestoration = 'manual';
		}
		
		let pageCtx : IPageContext<IApiPageResponse>
		let prevPage = this.page
		
		// save state of previous page
		if ( prevPage ) {
			prevPage.scrollY = window.scrollY
			prevPage.containerHeight = document.getElementById( 'content' )?.getBoundingClientRect().height
			let state = prevPage.native(true) as IApiPageResponse;
			state.stateType = 'page';
			window.history.replaceState( state, '' )
		}
		
		let spinner : LoadingSpinner | null = new LoadingSpinner();
		spinner.render();
		pageDispatcher( url )
		.then( (module) => {

			pageCtx = module.getPage()
//			if ( pageCtx.ssr ) {
//				// ssr page
//				
//				// in Firefox bleibt der Spinner erhalten, wenn man mit dem Back-Button zur Seite zurückkehrt
//				// => Spinner entfernen
//				window.addEventListener( 'unload', () => {
//					spinner.remove()
//				})
//				
//				this.forceLoadPage(url)
//				return;
//			}
			pageCtx.url = url;
			
			return pageProvider.loadPage( pageCtx, null )
			.then ( (json) => {

				if ( spinner ) {
					spinner.remove()
				}
				spinner = null;

				return this.doPageChange( pageCtx, json, 'push')
			})
			.then( (json) => {
				
				// restore kept popups
				this.popupHistoryKeeps.forEach( (item) => {
					this.addToPopupHistory( item, item.name )
				})
				this.popupHistoryKeeps.length = 0
				
				// revalidate data
				this.revalidatePage( pageCtx, json );
			})
		})
		.catch( (reason) => {

			this.popupHistoryKeeps.length = 0

			if ( spinner ) {
				spinner.remove()
			}
			
			if ( reason instanceof PageDispatchError ) {
				// link is not part of app
				externalRoute(url);
			} else if ( reason instanceof Error && reason.message.match( /Loading chunk [^ ]+ failed/ ) ) {
				// perhaps a new version
				this.forceLoadPage(url);
			} else {
				this.alertFailReason(reason, true, false)
				.then( () => {
					this.forceLoadPage(url);
				})
			}
			
		})
		
	}
	
	private popStateHandler( e : PopStateEvent ) {
		
		if ( this.popupHistory.length ) {
			
			this.closeRemainingPopups();
			
		} else if ( this.nextPageUrl ) {
			
			// clearing popup history is complete
			let url = this.nextPageUrl;
			this.nextPageUrl = '';
			// continue with page change
			this.navigateTo(url);

		} else if ( e.state ) {
			let state : IApiPageResponse = e.state;
		
			if ( state.stateType ) {
				
				if ( state.stateType == 'page' ) { //&& state.url !== locationHref
				
					if ( !this.page ) {
						// iOS Webkit triggert popstate Event beim Restart der Safari App,
						// deshalb hier der Test, ob !this.page in Chrome oder Firefox benötigt wird
						console.error( 'router.page is empty!!!' )
					}
					if ( this.page && state.checksum != this.page.checksum ) {
						// switch to new page
	
						if ('scrollRestoration' in history) {
							history.scrollRestoration = 'manual';
						}
				
						let url = state.url
						if ( url ) {
							let pageCtx	: IPageContext<IApiPageResponse>
							
							let spinner : LoadingSpinner | null = new LoadingSpinner();
							spinner.render();
	
							pageDispatcher( url )
							.then( (module) => {
								pageCtx = module.getPage()
								pageCtx.url = url;
								
								if ( pageCtx.readCache ) {
									state.fromCache = true;
									pageProvider.response = state
									return state
								} else {
									return pageProvider.loadPage( pageCtx, null )
								}
							})
							.then( (json) => {
	
								if ( spinner ) spinner.remove()
								spinner = null;
	
								return this.doPageChange( pageCtx, json, pageCtx.readCache ? 'none' : 'replace' );
							})
							.then( (json) => {
								
								// revalidate ?
								
							})
							.catch( (reason) => {
	
								if ( spinner ) {
									spinner.remove()
								}
	
								if ( reason instanceof PageDispatchError ) {
									// eslint-disable-next-line no-self-assign
									location.href = location.href;
								} else {
									this.alertFailReason(reason, true)
								}
								
							})
						}
					}
				} else if ( state.stateType == 'popup' ) {
					// back from external page to orphaned popup state
					// => go to previous state
					window.history.back()
				}
			}
		}
	}
	
	private setContentTitle( pageCtx : IPageContext<IApiPageResponse>, json : IApiPageResponse ) {

		if ( typeof pageCtx.title == 'function' ) {
			setContentTitle( pageCtx.title(json) )
		} else if ( typeof pageCtx.title == 'string' ) {
			setContentTitle( pageCtx.title )
		}

	}

	/**
	 * sets the current page to url of pageCtx.
	 * page must have been created before. 
	 * 
	 * @param pageCtx				contains the url
	 * @param historyStateAction	'none'		: don't change history
	 * 								'push'		: add page to history
	 * 								'replace'	: replace the current history state with current page
	 **/
	private setCurrentPage( pageCtx : IPageContext<IApiPageResponse>, historyStateAction : THistoryStateAction, scrollTo? : number ) {

		let stateUrl = pageCtx.url;
		if ( APP_BUILD ) {
			// do not replace the location url
			stateUrl = '';
			AppStorage.setItem( 'app-settings', 'current_page', { url : pageCtx.url } )
		}
		
		this.currentPage = pageCtx.url;
		if ( pageCtx.url && !pageCtx.ssr && ( historyStateAction != 'none' ) ) {
			this.page.url = pageCtx.url;
			let state = this.page.native(true) as IApiPageResponse
			state.stateType = 'page';
//			state.url = pageCtx.url
			if ( historyStateAction == 'push' ) {
				window.history.pushState( state, document.title, stateUrl );
			} else {
				window.history.replaceState( state, document.title );
			}
		}
	}
	
	/** revalidate page data */
	private revalidatePage( pageCtx : IPageContext<IApiPageResponse>, json : IApiPageResponse ) {
		
		if ( json.fromCache ) {
			let page = this.page;
			pageCtx.readCache = false
			pageProvider.loadPage( pageCtx, json )
			.then( (update) => {
				if ( update.status == 200 ) {
					// something changed
					if ( page == this.page ) {
						// we are still on the same page
						if ( pageCtx.processData ) {
							pageCtx.processData(update)
						}
						page.merge( update );
						page.onPageRevalidated();
						if ( !this.popupHistory.length ) {
							// history still points to this page
							let state = this.page.native(true) as IApiPageResponse
							state.stateType = 'page';
							window.history.replaceState( state, document.title );
						}
					}
				}
			})
		}
		
	}
	
	private doPageChange( pageCtx : IPageContext<IApiPageResponse>, json : IApiPageResponse, historyStateAction : THistoryStateAction, scrollTo? : number ) : Promise<IApiPageResponse> {
		let prevPage = this.page;
		
		// fix previous page content height
		this.fixPrevPageContentHeigth();
		
		if ( pageCtx.processData ) {
			pageCtx.processData(json)
		}
		
		// set title
		this.setContentTitle( pageCtx, json );
		
		// create new page
		this.page = new pageCtx.component( json ) as Page;
		
		// init page
		return this.page.init()
		.then( () => {
			
			if ( typeof this.page.containerHeight == 'number' ) {
				let content = document.getElementById( 'content' )
				if ( content ) {
					content.style.height = this.page.containerHeight + 'px';
				}
			}
			
//			if ( typeof scrollTo == 'number' ) {
//				this.page.scrollY = scrollTo;
//			}
			if ( typeof this.page.scrollY != 'number' ) {
				let scrollY = 0
				let stickyTrigger = document.getElementById( 'sticky-trigger' )
				let header = document.querySelector( '#page .header' );
				if ( stickyTrigger && header ) {
					// aktuelle Scrollposition
					scrollY = window.scrollY
					// position des Header im Viewport
					let triggerR = stickyTrigger.getBoundingClientRect()
					// Wert top des sticky headers (z.B. -3em RBL-mobile)
					let headerTop = parseInt( window.getComputedStyle(header).getPropertyValue('top') )
//					console.log( 'triggerR:', triggerR )
//					console.log( 'head.top:', headerTop )
//					console.log( 'scrollY :', window.scrollY )
					if ( triggerR.y + triggerR.height < headerTop ) {
						// der Header ist über der Sticky-Schwelle
						scrollY += triggerR.y + triggerR.height - headerTop
					}
				}
				this.page.scrollY = scrollY
			}
			
			window.scrollTo( 0, this.page.scrollY )
			
			this.setCurrentPage( pageCtx, historyStateAction )
			
			// fade in new page (render)
			return this.page.fadeIn()
			
		} )
		.then( () => {
			// fade out and remove previous page
			if ( prevPage ) {
				prevPage.close();
			}
			return json;
		})
	}
	
	private closeRemainingPopups() {
		
		// remove recent popup, that is manually marked to be closed
		for ( let i = this.popupHistory.length - 1; i >= 0; i-- ) {
			let item = this.popupHistory[i]
			
			if ( item.remove ) {
				if ( item.close ) {
					let dontClose = item.close( item )
					if ( dontClose ) {
						return;
					}
				}
				
				// resolve removeFromPopupHistory Promise
				if ( item.resolve ) {
					item.resolve();
					item.resolve = undefined;
				}
				
				// Element aus der Liste entfernen
				this.popupHistory.splice( i, 1 )
				
				return;
			}
		}
		
		// no manuel removement
		// pop last element
		let item = this.popupHistory.pop();
		if ( item && item.close ) {
			item.close( item )
		}

	}
	
	private fixPrevPageContentHeigth() {
		// fix previous page content height
		let  prevPage = this.page; 
		if ( prevPage ) {
			let pageNode = prevPage.getNode()
			if ( pageNode && pageNode.parentElement ) {
				let parent = pageNode.parentElement;
				let r = pageNode.getBoundingClientRect()
				let parentHeight = parent.getBoundingClientRect().height
//				console.log( 'fixPrevPageContentHeigth()', r.height, parentHeight )
				parent.style.height = parentHeight + 'px';
				pageNode.style.position = "fixed"
				pageNode.style.top = r.top + 'px'
				pageNode.style.left = r.left + 'px'
				pageNode.style.width = r.width + 'px'
			}
		}

	}

	/**
	 * Fehler in AlertBox anzeigen und loggen
	 * z.B. chunk- Ladefehler abfangen (reload)
	 * 
	 * @param reason		reject reason
	 * @param resolve?		reject aufheben => resolve(void)
	 * @param reload?		neu laden verhindern (false), oder erzwingen (true), oder auto (undefined)
	 */
	alertFailReason( reason : any, resolve? : boolean, reload? : boolean ) : Promise<any> {
		
		let dialogParams : TDialogParams | undefined;
		
		if ( reason === 'cancel' ) {
			if ( resolve ) {
				return Promise.resolve();
			}
			return Promise.reject( reason );
		} else if ( reason instanceof SyntaxError ) {
			// JSON error
			dialogParams = {
				title	: reason.name,
				text	: 'Fehler beim Laden der Daten.<br>' + reason.message + '<br>Die Seite wird neu geladen.',
			};
			if ( typeof reload == 'undefined' ) {
				// Web-Seite neu laden
				reload = true
			}
		} else if ( reason instanceof Error && reason.message.match( /Loading chunk [^ ]+ failed/ ) ) {
			// perhaps a new version
			dialogParams = {
				title	: 'Fehler',
				text	: 'Fehler beim Laden der Komponente.<br>Evtl. ist eine neue Version online.<br>Die Seite wird neu geladen.',
			};
			if ( this.updateWaiting ) {
				updateServiceWorker()
				reload = false
			} else if ( typeof reload == 'undefined' ) {
				// Web-Seite neu laden
				reload = true
			}
			
		} else if ( reason instanceof ApiError && reason.messages ) {
			// error messages from api
			dialogParams = {
				title	: 'Fehler',
				text	: reason.messages.join( '<br>' ),
			};
		}
	
		if ( !dialogParams ) {
			log( 'alertFailReason: unbekannter Fehler', reason );
			dialogParams = {
				title	: 'Fehler',
				text	: 'Es ist ein unbekannter Fehler aufgetreten.<br>' + reason + (reload ? '<br>Die Seite wird neu geladen.' : ''),
			};
//			if ( typeof reload == 'undefined' ) {
//				// Web-Seite neu laden
//				reload = true
//			}
		}

		return openAlertBox(dialogParams)
		.then( ( res ) => {
			if ( reload ) {
				window.location.reload();
			}
			if ( resolve ) {
				return Promise.resolve();
			}
			return Promise.reject( reason );
		})
		.catch( (reason) => {
			console.log( reason )
		})
	}
	
	updateHistoryState( page : Page ) {
		// something changed
		if ( page == this.page ) {
			// we are still on the same page
			if ( !this.popupHistory.length ) {
				// history still points to this page
				let state = this.page.native(true) as IApiPageResponse
				state.stateType = 'page';
				window.history.replaceState( state, document.title );
			}
		}

	}
	
	addToPopupHistory( item : IPopupHistoryItem, stateName : string ) {
		
		this.popupHistory.push( item )
		window.history.pushState( { type: 'popup' }, stateName )
		
	}
	
	/**
	 * manuell ein Popup aus der Liste entfernen.
	 * ruft nicht den close callback auf, um ein Popup zu schließen
	 * 
	 * @param id
	 */
	removeFromPopupHistory( id : number ) : Promise<void> {
	
		const item = this.popupHistory.find( (item) => {
			return item.id == id;
		});
		if ( item ) {
			//console.log( 'remove:', item.name, item.id )
			
			// Element zum entfernen markieren
			item.remove = true
			// callback entfernen
			item.close = undefined;
			
			// in der History zurückgehen um das Element zu entfernen
			window.history.back();
			
			return new Promise<void>(	(resolve) => {
				item.resolve = resolve
			});

		} else {
			return Promise.resolve();
		}
	
	}
	
}

export const appRouter = new Router
