import "./loading-spinner.ddt";
import { DDcomponent, Component } from "ddt/DDcomponent";
import { prop } from "ddt/DDmodel";


@Component( 'loading-spinner' )
export class LoadingSpinner extends DDcomponent {
	
	@prop() caption	: string = 'loading'
	
	render( parent? : Node | Element | null ) : Node {

		if ( !parent ) {
			parent = document.getElementById('app');
			if ( !parent ) {
				parent = document.body;
			}
		}

		// render template
//		this.node = this.template( this, parent );
//		
//		return this.node;
		return super.render(parent);
	}
	
}
