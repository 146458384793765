// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 0.5em;
  margin: 0;
  min-height: 2em;
  min-width: 2em;
  font-size: 1em;
  font-weight: bold;
  white-space: nowrap;
  line-height: normal;
  border-radius: 0.375em;
  outline: none;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #3D75B8;
  background-color: transparent;
  background-image: none;
  /* Win10 fix */
  /* 	* { */
  /* 		cursor: default; */
  /* 	} */
  /* checkbox button */
  /* file input button */
}
.button:-moz-focus-inner {
  padding: 0;
  border: none;
}
.button:focus {
  outline: none;
}
.button > IMG {
  margin: 1px;
}
.button > SPAN {
  margin: 0;
  text-align: left;
  background-repeat: no-repeat;
}
.button > .fa-stack + span {
  margin-left: 0.25em;
}
.button > .svg-inline--fa + span {
  margin-left: 0.5em;
}
.button > span + .fa-stack, .button > span + .svg-inline--fa {
  margin-left: 0.5em;
}
.button[disabled] {
  opacity: 0.35;
}
.button.is-active, .button[is-active=true], .button.outline[is-active=true] {
  color: #4EA4D9;
  background-color: #E0F0FF;
}
.button.outline {
  font-weight: normal;
  color: #262223;
  border: 1px solid #bbb;
  background-color: #FAFAFA;
}
.button.circle {
  background-image: #FAFAFA;
  border: 3px solid #bbb;
  padding: 0;
  border-radius: 1em;
}
.button.circle .svg-inline--fa {
  vertical-align: middle;
  max-width: 1em;
}
.button.small-text {
  flex-direction: column;
  padding: 0.5em;
}
.button.small-text SPAN {
  font-size: 0.625em;
  margin: 0.25em 0 0;
}
.button INPUT[type=radio],
.button INPUT[type=checkbox] {
  display: none;
  opacity: 0;
}
.button.sign-activation INPUT[type=radio] ~ svg,
.button.sign-activation INPUT[type=radio] ~ span,
.button.sign-activation INPUT[type=checkbox] ~ svg,
.button.sign-activation INPUT[type=checkbox] ~ span {
  opacity: 0.4;
}
.button.sign-activation INPUT[type=radio]:checked ~ svg,
.button.sign-activation INPUT[type=radio]:checked ~ span,
.button.sign-activation INPUT[type=checkbox]:checked ~ svg,
.button.sign-activation INPUT[type=checkbox]:checked ~ span {
  opacity: 1;
}
.button INPUT[type=file] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.button-group {
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}
.button-group .button {
  margin-right: 0;
  margin-left: 0;
  border-radius: 0;
}
.button-group .button.outline {
  border-right-width: 0;
}
.button-group .outline:first-child,
.button-group .outline.is-first {
  border-top-left-radius: 0.375em;
  border-bottom-left-radius: 0.375em;
}
.button-group .outline:last-child {
  border-top-right-radius: 0.375em;
  border-bottom-right-radius: 0.375em;
  border-right-width: 1px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
