// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* elements that have click handlers */
/* elements that have click handlers */
.clickable {
  user-select: none;
  cursor: pointer;
  -webkit-touch-callout: none;
}

/* clicked state */
.clicked {
  background-color: rgba(213, 226, 240, 0.5);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
