/**
 * page module loader
 */

export class PageDispatchError extends Error {
	
	pageKey : string
	
	constructor( message : string, pageKey : string ) {
		super(message); // (1)
		this.name = "PageDispatchError"; // (2)
		this.pageKey = pageKey
	}
}

/** 
 * pageKey is the page path without origin and file extension, parameters/anchors 
 **/
export function getPageKey( url : string ) : string {

	let pageKey = 'index';

	// remove protocol and domain (origin)
	if ( typeof url == 'string' ) {
		url = url.replace( /^https?:\/\/[^/]+/, '' );
		
		let m = url.match( /^\/([^.#?]+)/ );
		if ( m ) {
			pageKey = m[1];
		}
	}

	return pageKey;
}

export function pageDispatcher( url : string ) : Promise<any> {

	let pageKey = getPageKey( url );
	
	switch ( pageKey ) {
		case 'index':
			// Startseite
			return import(/* webpackChunkName: "page-index" */				'../site/start-page/start-page' );
		case 'user/login-page':
			return import(/* webpackChunkName: "page-login-dse" */			'../user/page-login-dse/page-login-dse' );
		case 'user/register':
		case 'user/zustimmen':
			return import(/* webpackChunkName: "page-register" */			'../user/page-register/page-register' );
		case 'user/user_remind':
			return import(/* webpackChunkName: "page-password-reset" */		'../user/page-password-reset/page-password-reset' );
		case 'user/settings':
			return import(/* webpackChunkName: "page-user-settings" */		'../user/page-user-settings/page-user-settings' );
		case 'user/profil':
			return import(/* webpackChunkName: "page-profile" */			'../user/page-profile/page-profile' );
		case 'artikel':
		case 'rblinfo':
		case 'user/datenschutz':
		case 'user/betatest':
			return import(/* webpackChunkName: "page-artikel" */			'../artikel/page-artikel/page-artikel' );
//			return import(/* webpackChunkName: "page-ssr-content" */		'../site/page-ssr-content/page-ssr-content' );
		case 'kontakt':
			return import(/* webpackChunkName: "page-contact" */			'../user/page-contact/page-contact' );
		case 'assists':
			return import(/* webpackChunkName: "page-liga-assists" */		'../liga/page-liga-assists/page-liga-assists' );
		case 'torschuetzen':
			return import(/* webpackChunkName: "page-liga-goals" */			'../liga/page-liga-goals/page-liga-goals' );
		case 'bestespieler':
		case 'playersofthematch':	
			return import(/* webpackChunkName: "page-liga-stars" */			'../liga/page-liga-stars/page-liga-stars' );
		case 'bestekeeper':
		case 'bestekeeper2':
			return import(/* webpackChunkName: "page-liga-keepers" */		'../liga/page-liga-keepers/page-liga-keepers' );
		case 'tabelle':
			return import(/* webpackChunkName: "page-tabelle" */			'../liga/page-tabelle/page-tabelle' );
		case 'tabelle-friendlies':
			return import(/* webpackChunkName: "page-tabelle-friendlies" */	'../liga/page-tabelle-friendlies/page-tabelle-friendlies' );
		case 'history-tabelle':
			return import(/* webpackChunkName: "page-history-tabelle" */	'../liga/page-history-tabelle/page-history-tabelle' );
		case 'spielinfo':
			return import(/* webpackChunkName: "page-spiel" */				'../liga/page-spiel/page-spiel' );
		case 'aufstellung':
			return import(/* webpackChunkName: "page-aufstellung" */		'../liga/page-aufstellung/page-aufstellung' );
		case 'ergebnisse':
		case 'lastresults':
			return import(/* webpackChunkName: "page-recent-results" */		'../liga/page-recent-results/page-recent-results' );
		case 'nextgames':
		//	return import(/* webpackChunkName: "page-next-games" */			'../liga/page-next-games/page-next-games' );
			return import(/* webpackChunkName: "page-next-games-byday" */	'../liga/page-next-games-byday/page-next-games-byday' );
		case 'chronik':
			return import(/* webpackChunkName: "page-chronik" */			'../liga/page-chronik/page-chronik' );
		case 'korunde':
			return import(/* webpackChunkName: "page-spieltag" */			'../liga/page-spieltag/page-spieltag' );
		case 'head2head':
			return import(/* webpackChunkName: "page-head2head" */			'../liga/page-head2head/page-head2head' );
		case 'teaminfo':
			return import(/* webpackChunkName: "page-team-saison" */		'../team/page-team-saison/page-team-saison' );
		case 'teamassists':
			return import(/* webpackChunkName: "page-team-assists" */		'../team/page-team-assists/page-team-assists' );
		case 'teamgoalscorer':
			return import(/* webpackChunkName: "page-team-goals" */			'../team/page-team-goals/page-team-goals' );
		case 'teamspiele':
			return import(/* webpackChunkName: "page-team-games" */			'../team/page-team-games/page-team-games' );
		case 'teamscorer':
			return import(/* webpackChunkName: "page-team-scorers" */		'../team/page-team-scorers/page-team-scorers' );
		case 'teamstars':
			return import(/* webpackChunkName: "page-team-stars" */			'../team/page-team-stars/page-team-stars' );
		case 'spieler':
			return import(/* webpackChunkName: "page-team-players" */		'../team/page-team-players/page-team-players' );
		case 'teamhistory':
			return import(/* webpackChunkName: "page-team-history" */		'../team/page-team-history/page-team-history' );
		case 'teamstory':
			return import(/* webpackChunkName: "page-team-story" */			'../team/page-team-story/page-team-story' );
		case 'teamtermine':
			return import(/* webpackChunkName: "page-team-events" */		'../termine/page-team-events/page-team-events' );
		case 'user/postfach':
		case 'user/message_thread':
			return import(/* webpackChunkName: "page-postfach" */			'../messaging/page-postbox/page-postbox' );
		case 'kalender':
			return import(/* webpackChunkName: "page-kalender" */			'../termine/page-calendar-month/page-calendar-month' );
		case 'user/usertermine':
		case 'manager/sp_termine':
			return import(/* webpackChunkName: "page-user-events" */		'../termine/page-user-events/page-user-events' );

		case 'blt/tsp_ew':
			return import(/* webpackChunkName: "page-tsp-ew" */				'../tippspiel/page-tsp-ew/page-tsp-ew' );
		case 'blt/tsp_tw':
			return import(/* webpackChunkName: "page-tsp-tw" */				'../tippspiel/page-tsp-tw/page-tsp-tw' );
		case 'blt/tsp_tipps':
			return import(/* webpackChunkName: "page-tsp-tipps" */			'../tippspiel/page-tsp-tipps/page-tsp-tipps' );
		case 'blt/bltabelle':
			return import(/* webpackChunkName: "page-bl-tabelle" */			'../tippspiel/page-bl-tabelle/page-bl-tabelle' );
		case 'blt/tsp_dfbupd':
			return import(/* webpackChunkName: "page-bl-update" */			'../tippspiel/page-tsp-update-bl/page-tsp-update-bl' );
		case 'blt/tsp_profil':
			return import(/* webpackChunkName: "page-tsp-profile" */		'../tippspiel/page-tipper-profile/page-tipper-profile' );
		case 'blt/tsp_anmeldung':
			return import(/* webpackChunkName: "page-tsp-anmeldung" */		'../tippspiel/page-tsp-anmeldung/page-tsp-anmeldung' );
		case 'blt/tsp_man_upd':
			return import(/* webpackChunkName: "page-tsp-man-upd" */		'../tippspiel/page-tsp-man-upd/page-tsp-man-upd' );

		case 'spielort':
			return import(/* webpackChunkName: "page-ort" */				'../orte/page-ort/page-ort' );
		case 'spielorte':
			return import(/* webpackChunkName: "page-orte" */				'../orte/page-orte/page-orte' );
		case 'rblanm':
		case 'hallenmasters/anmeldung':
		case 'kleinfeld/anmeldung':
		case 'turnier/anmeldung':	// test-serie Team-Anmeldung
			return import(/* webpackChunkName: "page-turnier-anmeldung" */	'../turnier/page-anmeldung/page-anmeldung' );
		case 'rblanm-singles':
		case 'hallenmasters/singles':
		case 'kleinfeld/singles':
		case 'turnier/singles':		// test-serie Spieler-Anmeldung
			return import(/* webpackChunkName: "page-register-single" */	'../turnier/page-register-single/page-register-single' );
		case 'turnier':
			return import(/* webpackChunkName: "page-turnier" */			'../turnier/page-turnier/page-turnier' );
		case 'partner/partner':
			return import(/* webpackChunkName: "page-partner" */			'../partner/page-partner/page-partner' );

		case 'forum/themen':
			return import(/* webpackChunkName: "page-forum-themes" */		'../forum/page-themes/page-themes' );
		case 'forum/recent':
			return import(/* webpackChunkName: "page-forum-recent" */		'../forum/page-recent-threads/page-recent-threads' );
		case 'forum/threads':
			return import(/* webpackChunkName: "page-forum-threads" */		'../forum/page-forum-threads/page-forum-threads' );
		case 'forum/thread':
			return import(/* webpackChunkName: "page-forum-thread" */		'../forum/page-forum-thread/page-forum-thread' );
		case 'forum/search':
			return import(/* webpackChunkName: "page-forum-search" */		'../forum/page-forum-search/page-forum-search' );
		case 'forum/anmeldung':
			return import(/* webpackChunkName: "page-forum-anmeldung" */	'../forum/page-forum-anmeldung/page-forum-anmeldung' );
		case 'forum/profil':
			return import(/* webpackChunkName: "page-forum-profil" */		'../forum/page-forum-profil/page-forum-profil' );

		case 'umfragen/umfrage_edit':
			return import(/* webpackChunkName: "page-my-polls" */			'../polls/page-my-polls/page-my-polls' );
		case 'umfragen/umfrage_erg':
			return import(/* webpackChunkName: "page-poll-results" */		'../polls/page-poll-results/page-poll-results' );
		case 'umfragen/umfrage_vote':
			return import(/* webpackChunkName: "page-poll-vote" */			'../polls/page-poll-vote/page-poll-vote' );

		case 'galery':
			return import(/* webpackChunkName: "page-galery" */				'../foto/page-galery/page-galery' );
	}
	
	if ( (pageKey == 'masterstab') || pageKey.match( /\/masterstab$/ )) {
			return import(/* webpackChunkName: "page-masters-tab" */		'../turnier/page-masters-tab/page-masters-tab' );
	}
	
	return Promise.reject( new PageDispatchError( 'pageDispatcher: pageKey "' + pageKey + '" not found', pageKey ) );
}
