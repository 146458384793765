// compiled by ddt-compiler
// src: src\components\loading-spinner\loading-spinner.ddt

import { DDNode, DDCallbackList, getFalseValue, setTextVariableCb, addTemplate } from 'ddt/DDtemplate';
import { implementsBinding } from 'ddt/DDmodel';
import './loading-spinner.scss';


export function loadingSpinner( data1 : any, p : Node ) : Node {
	let f : DDNode,n : DDNode,callbacks1 : DDCallbackList = new Set()
	f = n = document.createElement("div");
	// outputNode: element
	// outputAttr: attr-text
	n.className = "loading-spinner";
	;(function(p : Element) {
		let n : DDNode
		n = document.createElement("div");
		// outputNode: element
		// outputAttr: attr-text
		n.className = "frame";
		;(function(p : Element) {
			let n : DDNode
			n = document.createElement("div");
			// outputNode: element
			// outputAttr: attr-text
			n.className = "spinner";
			;(function(p : Element) {
				let n : DDNode
				n = document.createElement("div");
				// outputNode: element
				// outputAttr: attr-text
				n.className = "circle fast";
				p.appendChild(n);
				n = document.createElement("div");
				// outputNode: element
				// outputAttr: attr-text
				n.className = "circle slow";
				p.appendChild(n);
				n = document.createElement("div");
				// outputNode: element
				// outputAttr: attr-text
				n.className = "text";
				;(function(p : Element) {
					let n : DDNode
					// Text Node Variable
					// path: [6,22,11]caption; level: 1; lev: 1; path-len: 1; vPath: data1:o.caption
					if ( typeof data1.caption == "function") {
						console.log("%cERROR:%c %ccaption%c is a function, use %ccaption()%c instead in\nC:/Users/Josef_2/Development/eclipse-php/rbl/viewts/rbl/src/components/loading-spinner/loading-spinner.ddt [6,22]", "color:red", "color: inherit", "color: blue;font-weight:bold", "color: inherit;font-weight:normal", "color: blue;font-weight:bold", "color: inherit;font-weight:normal");
						console.log("source:\n5: 			<div class=\"circle slow\"></div>\n6: 			<div class=\"text\">%c{{caption}}%c</div>\n7: 		</div>", "color:red", "color:inherit");
					}
					n = document.createTextNode(data1.caption || getFalseValue(data1.caption));
					if (!implementsBinding(data1)) {
						console.log("%cERROR:%c no databinding. %cdata1%c (= "+data1+" : "+(typeof data1)+(!data1 ? "; use %cdata1?%c" : "")+") is not inherited from DDobject in\nC:/Users/Josef_2/Development/eclipse-php/rbl/viewts/rbl/src/components/loading-spinner/loading-spinner.ddt [6,22]", "color:red", "color: inherit", "color: blue;font-weight:bold", "color: inherit;font-weight:normal", "color: blue;font-weight:bold", "color: inherit;font-weight:normal");
						console.log("source:\n5: 			<div class=\"circle slow\"></div>\n6: 			<div class=\"text\">%c{{caption}}%c</div>\n7: 		</div>", "color:red", "color:inherit");
					}
					else {
						setTextVariableCb(callbacks1, n, data1, "caption", null);
					}
					p.appendChild(n);
				})(n as Element);
				p.appendChild(n);
			})(n as Element);
			p.appendChild(n);
		})(n as Element);
		p.appendChild(n);
	})(n as Element);
	p.appendChild(n);
	if (callbacks1.size) {
		f.ddCallbacks = callbacks1;
	}
	return f;
}
addTemplate('loading-spinner', loadingSpinner);