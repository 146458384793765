import { DDobject, DesignTypeGetter, implementsBinding, watch, prop } from 'ddt/DDmodel';
import { immediately } from '../utils/delay-promise';
import { StorageAPIWrapper } from './storage-driver-forage';


const AppStorageName	= 'rbl'
const AppCacheName		= 'rbl-cache'
export const AppStorage = new StorageAPIWrapper( AppStorageName );

export interface IJsonSettings {
	[key:string] : any 
}

/**
 * @Setting decorator sets the storage target type of a setting property
 * this also makes the setting property observable
 */
export function Setting( designTypeGetter? : DesignTypeGetter ) {
	return function (proto: ModuleSettings, propertyKey: string) {
		
		if ( !Object.getOwnPropertyDescriptor(proto, '__settingKeys') ) {
			// 
			Object.defineProperty(proto, '__settingKeys', {
				// make it invisible in own properties
				enumerable: false,
				// make it undeletable
				configurable: false,
				value: new Set,
			});
			// insert target types of parent
			let parentProto : ModuleSettings = Object.getPrototypeOf(proto);
			if ( parentProto.__settingKeys ) {
				parentProto.__settingKeys.forEach( (key) => {
					proto.__settingKeys?.add(key);
				} )
			}
		}
		proto.__settingKeys?.add(propertyKey);
		prop( designTypeGetter )( proto, propertyKey );
		
		// add route to save
		let saveDescriptor = Object.getOwnPropertyDescriptor( Object.getPrototypeOf(proto), 'save');
		if ( saveDescriptor ) {
			watch( propertyKey )( proto, 'save', saveDescriptor );
		}

	}
}

export interface ISettings { 
	[key: string] : any  
}

export class ModuleSettings extends DDobject {
	__settingKeys? : Set<string>
	[key: string] : any
			
	storageName : string
	
	@prop( )
	protected loadState : number
	
	constructor( name : string, _default : ISettings ) {
		super( _default )
		
		this.storageName = 'cfg-' + name;
		this.loadState = 0; // not loaded
		this.allowWildcards(true);

	}
	
	load() : Promise<void> {
		
		if ( !this.loadState ) {
			this.loadState = 1; // loading
			return AppStorage.getItem( AppCacheName, this.storageName )
			.then( (data : ISettings) => {

				if ( data ) {
					this.merge( data )
				}
				
				return immediately()
			})
			.then( () => {
				// loaded => enable auto save
				this.loadState = 2; 
			} )

		} else if ( this.loadState == 1 ) {
			// currently loading
			return new Promise( (resolve, reject) => { 
				// wait for state changing to 'loaded'
				let removeOnChange = this.on('change', 'loadState', (e, val) => {
					if ( val == 2 ) {
						resolve()
					} else {
						reject()
					}
					// remove listener
					removeOnChange();
				})
			});
		} else {
			return Promise.resolve();
		}
	}

	/**
	 * load configuration values
	 *
	 * param key		name of the configuration parameter
	 * 
	 * returns Promise<value>
	 */
	value( key : string ) : Promise<any> {
		
		return this.load().then( () => {
			let value = this[key];
			return value;
		});

	}
	
	jsonSettings() : IJsonSettings {
		
		let json : {[key:string] : any } = {}
		if ( this.__settingKeys ) {
			this.__settingKeys.forEach( (key) => {
				if ( this[key] instanceof ModuleSettings ) {
					json[key] = this[key].jsonSettings()
				} else if ( implementsBinding(this[key]) ) {
					if ( this[key].jsonSettings ) {
						json[key] = this[key].jsonSettings()
					} else {
						json[key] = this[key]._hidden.props;
					}
				} else {
					json[key] = this[key];
				}
			})
		}
		
		return json;
	}
	
	protected save() : IJsonSettings {
		
		if ( this.loadState < 2 ) return {};

		let settings = this.jsonSettings() 
		AppStorage.setItem( AppCacheName, this.storageName, settings )
		
		return settings
	}
	
	static mixinProp( mixinClass : typeof ModuleSettings, prop : string) {
		if ( prop == '__settingKeys' ) {
			// special case: setting properties
			
			// insert setting properties of mixin
			const keys = mixinClass.prototype.__settingKeys;
			if ( keys ) {
				keys.forEach( (value : any) => {
					if ( this.prototype.__settingKeys ) {
						this.prototype.__settingKeys.add(value);
					}
				})
			}
		} else {
			DDobject.mixinProp.call(this, mixinClass as unknown as typeof DDobject, prop);
		}
	}
	
	static removeMixinProp( mixinClass : typeof ModuleSettings, prop : string) {
		if ( prop == '__settingKeys' ) {
			// special case: setting properties

			// remove setting properties of mixin
			const keys = mixinClass.prototype.__settingKeys;
			if ( keys ) {
				keys.forEach( (value : any, key : string) => {
					if ( this.prototype.__settingKeys ) {
						this.prototype.__settingKeys.delete(key);
					}
				})
			}
		} else {
			DDobject.removeMixinProp.call(this, mixinClass as unknown as typeof DDobject, prop)
		}
	}

}
