// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}
.loading-spinner .frame {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(240, 240, 240, 0.75);
  padding: 1em;
  border-radius: 1em;
}
.loading-spinner .frame .spinner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 112px;
}
.loading-spinner .frame .spinner .circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 4px solid rgba(136, 136, 136, 0.3137254902);
  border-radius: 50%;
  box-sizing: border-box;
}
.loading-spinner .frame .spinner .slow {
  border-top-color: transparent;
  animation-name: z-spin;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  transform-style: preserve-3d;
  transform-origin: center;
}
@keyframes z-spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
.loading-spinner .frame .spinner .fast {
  border-right-color: transparent;
  animation-name: z-spin;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  transform-style: preserve-3d;
  transform-origin: center;
}
@keyframes z-spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
.loading-spinner .frame .text {
  color: #7c6e72;
  font-weight: bold;
  /* keep text centered */
}
.loading-spinner .frame .text::before {
  content: "...";
  visibility: hidden;
}
.loading-spinner .frame .text::after {
  content: "...";
}
@media (min-width: 900px) {
  .loading-spinner .frame {
    transform: translateX(-150px);
  }
}
@media (min-width: 1260px) {
  .loading-spinner .frame {
    transform: translateX(-140px) translateY(70px);
  }
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
