import { prop, ArrayOf, DDevent, watch } from "ddt/DDmodel";
import { MenuItems, MenuItem, IMenuItem } from "./menuitem";
import { ItemDB, DbType } from "../item-db";
import { IDatensatz, Datensatz } from "../ds";
import { DDindexedArray, ArrayIndex } from "ddt/DDindexedArray";

export interface IMenu extends IDatensatz {
	groups		: IMenuItem[] | MenuItems
}

export class Menu extends Datensatz implements IMenu {
	
	@prop() isOpen			: boolean
	@prop() isHeader		: boolean

	@prop( () => MenuItems )
	groups					: MenuItems

	@prop( () => MenuItem )
	selected				: MenuItem | undefined
	
	@prop() parentCaption	: string

	/**
	 * searches a menu item id in all category groups
	 * 
	 * param id		searched menu item id
	 * 
	 * returns { groupItem : MenuItem, menuItem : MenuItem } item or null
	 */
	find( id : string ) : MenuItem | undefined {
		let menuItem : MenuItem | undefined;
		
		for ( let i = 0, len = this.groups.length; i < len; i++ ) {
			let mi = this.groups[i];

			if ( mi.menuitems ) {
				menuItem = mi.menuitems.item( id )
				if ( menuItem ) {
					// found
					break;
				}
			}
			
		}
		
		return menuItem;
	}

	@watch( 'selected' )
	watchSelected( e : DDevent ) {
		
//		if ( e.prevValue instanceof MenuItem ) {
//			// deselect previous selection
//			e.prevValue.selected = false;
//		}
		// alle Items de-selektieren
		this.groups.forEach( (group) => {
			if ( group.menuitems ) {
				group.menuitems.forEach( (menuitem) => {
					menuitem.selected = false
				})
			}
		})
		
		if ( e.value instanceof MenuItem ) {
			// select new selection
			e.value.selected = true;
		}
		
	}
}

@ArrayOf( () => Menu )
@ArrayIndex( 'id' )
export class Menus extends DDindexedArray<Menu> {}

/* Menu DB */

@DbType( Menu, Menus )
class MenuDB extends ItemDB<IMenu, Menu, Menus> {}

export const menuDB = new MenuDB
