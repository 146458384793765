import { appRouter } from "../../app/router";


let elevatorObserver : IntersectionObserver

if (
		"IntersectionObserver" in window && 
		"isIntersecting" in IntersectionObserverEntry.prototype
) {
	let threshold = []
	for ( let i = 0.01; i <= 1; i += 0.01 ) {
		threshold.push( i )
	}
	
	elevatorObserver = new IntersectionObserver( function(entries) {
		entries.forEach( function(entry) {
			let brand = document.querySelector( '.header-bar .brand' )
//			let elevator = document.querySelector( '.header-bar .brand .elevator' )
			let logo = document.querySelector( '.header-bar .brand a' )
			if ( logo instanceof HTMLElement && brand instanceof HTMLElement ) {	//} && elevator instanceof HTMLElement ) {
				if ( brand.getBoundingClientRect().width < 80 ) {
					logo.style.transform = '';
				} else {
					let width = 88 + 28 * entry.intersectionRatio
//					let height = 88 + 132 * entry.intersectionRatio
//					let scale = width / 116
//					logo.style.transform = 'scale(' + scale + ')'
//					console.log( 'Logo intersection', entry.intersectionRatio, scale )
					logo.style.width  = width + 'px'
					logo.style.height = width + 'px'
				}
			}
		});
	},
	{
		threshold: threshold,
	});
}

export function hydrateHomeLinks() {
	
	let links = document.querySelectorAll( '.brand .elevator a' )
	if ( links ) {
		links.forEach( (link) => {
			link.addEventListener( 'click', (e) => {
				if ( e.currentTarget instanceof HTMLAnchorElement ) {
					e.preventDefault();
					
					appRouter.navigateTo( '/index.php' );
					
				}
			})
		})
		
		if ( elevatorObserver ) {
			let desktopElevator = document.querySelector( '.header-top' )
			if ( desktopElevator ) {
				elevatorObserver.observe( desktopElevator );
			}
		}
	}
	
}