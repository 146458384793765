import { User, IUser } from "./user";
import { prop, DDarray, DDevent, watch } from "ddt/DDmodel";
import { Teams, ITeam, Team } from "../team/teams";
import { ModuleSettings, Setting } from "../../controllers/storage";


export interface ISiteuser {
	loginStatus	: number
	user		: IUser | User | null
	teams		: ITeam[] | Teams
	rights		: string[] | DDarray<string>
}

export class Siteuser extends ModuleSettings implements ISiteuser {

	// Login Status
	/** ERROR_FORBIDDEN = 0 */
	static ERROR_FORBIDDEN			=  0;
	/** ERROR_NOT_LOGGED_IN = 1 */
	static ERROR_NOT_LOGGED_IN		=  1;
	/** LOGIN_OK = 2 */
	static LOGIN_OK					=  2;
	/** ERROR_EMAIL_INVALID = 4 */
	static ERROR_EMAIL_INVALID		=  4;
	/** ERROR_DSE_NOT_ACCEPTED = 8 */
	static ERROR_DSE_NOT_ACCEPTED	=  8;
	
	@prop() loginStatus	: number
	
	@prop( () => User )
	user				: User | null
	
	@prop( () => Teams )
	teams				: Teams
	
	@prop( () => DDarray )
	rights				: DDarray<string>
	
	@prop( () => Team )
	team				: Team | null
	
	@Setting() 
	teamid				: string
	
	@Setting() 
	loginRedirect		: string
	
	@Setting()
	authToken			: string
	
	@Setting()
	mapsOptIn			: boolean
	
//	@Setting()
//	forceDesktop		: boolean
	
	constructor() {
		super( 'siteuser', {} );
	
		this.loginStatus	= Siteuser.ERROR_NOT_LOGGED_IN;
		this.loginRedirect	= '';
		this.authToken		= '';
		this.teams			= new Teams();
		this.teamid			= '';
		this.mapsOptIn		= false;
//		this.forceDesktop	= false;
		
//		this.load();
	}

	/**
	 * reset site user
	 **/
	reset() {

		this.user = null;
		this.teams.length = 0
		if ( this.rights ) {
			this.rights.length = 0
		}
		this.team = null;
		this.teamid = ''
		this.loginStatus	= Siteuser.ERROR_NOT_LOGGED_IN;
		this.loginRedirect	= '';
		this.authToken		= '';

	}
	
	hasRightOf( right : string ) : boolean {
		return !!this.rights && ( this.rights.indexOf( right ) >= 0 );
	}
	
	@watch( 'team' )
	watchTeam( e : DDevent ) {
		if ( this.team ) {
			this.teamid = this.team.id;
		} else {
			this.teamid = '';
		}
	}
	
	@watch( 'loginStatus' )
	loginOk() : boolean {
		return this.loginStatus == Siteuser.LOGIN_OK;
	}
	
//	@watch( 'forceDesktop' )
//	watchForceDesktop( e : DDevent ) {
//		if ( typeof e.prevValue !== 'undefined' ) {
//			let vp = document.querySelector('meta[name="viewport"]');
//			if ( vp ) {
//				if ( this.forceDesktop ) {
//					vp.setAttribute('content', 'width=1260' );
//				} else {
//					vp.setAttribute('content', 'width=device-width, initial-scale=1' );
//				}
//			}
//		}
//	}
	
}

export const siteuser = new Siteuser();
