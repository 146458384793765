import * as localForage from 'localforage';

export class StorageAPIWrapper {

	private tables		: { [name: string] : LocalForage }

	constructor( database: string ) {
		this.tables = {}
	}

	public init() : Promise<boolean> {
		return Promise.resolve( true )
	}
	
	private setTable( table : string ) : LocalForage {
		
		if ( !this.tables[table] ) {
			this.tables[table] = localForage.createInstance({
				name: table,
			});
		}

		return this.tables[table]
	}
	
	public setItem( tableName : string, key : string, value : { [key : string] : any } ) : Promise<any> {
		let table = this.setTable(tableName)
		return table.setItem( key, value )
		.catch( (reason) => {
			console.error( 'StorageAPIWrapper.setItem(' + tableName + ', ' + key + ')', reason );
		})
	}

	public getItem( tableName : string, key : string ): Promise<any> {
		let table = this.setTable(tableName)
		return table.getItem( key )
		.catch( (reason) => {
			console.error( 'StorageAPIWrapper.getItem(' + tableName + ', ' + key + ')', reason );
		})
	}
	
	public getAllKeys( tableName : string ) : Promise<Array<string>> {
		let table = this.setTable(tableName)
		return table.keys()
	}
	
	public removeItem( tableName : string, key : string ) : Promise<void> {
		let table = this.setTable(tableName)
		return table.removeItem( key )
		.catch( (reason) => {
			console.error( 'StorageAPIWrapper.removeItem(' + tableName + ', ' + key + ')', reason );
		})
	}
	
	public clear( tableName : string ) : Promise<void> {
		let table = this.setTable(tableName)
		return table.clear()
		.catch( (reason) => {
			console.error( 'StorageAPIWrapper.clear(' + tableName + ')', reason );
		})
	}
}
