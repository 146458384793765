import { Workbox } from 'workbox-window';
import { loadSwUpdate } from '../components/sw-update/sw-update-loader';
import { pushProvider } from '../controllers/push-provider';
import { appRouter } from './router';
import { LoadingSpinner } from '../components/loading-spinner/loading-spinner';

var wb : Workbox | null = null

export function startServiceWorker() {
	
	if ('serviceWorker' in navigator) {
		
		let updateComponent = false
	
		// https://medium.com/@webmaxru/workbox-4-implementing-refresh-to-update-version-flow-using-the-workbox-window-module-41284967e79c
		wb = new Workbox('/rbl-sw.js');
	
		// https://developers.google.com/web/tools/workbox/guides/advanced-recipes
		// siehe auch service-worker/rbl-sw.ts
		// Add an event listener to detect when the registered
		// service worker has installed but is waiting to activate.
		wb.addEventListener('waiting', (event) => {
			console.log( 'WB: waiting' );
			
			if ( !updateComponent ) {
				updateComponent = true
				appRouter.updateWaiting = true;
				loadSwUpdate()
			}
			
			// `event.wasWaitingBeforeRegister` will be false if this is
			// the first time the updated service worker is waiting.
			// When `event.wasWaitingBeforeRegister` is true, a previously
			// updated same service worker is still waiting.
			// You may want to customize the UI prompt accordingly.
			
	//		// Assuming the user accepted the update, set up a listener
	//		// that will reload the page as soon as the previously waiting
	//		// service worker has taken control.
	//		wb.addEventListener('controlling', (event) => {
	//			console.log( 'WB: controlling' );
	//			window.location.reload();
	//		});
	//		wb.addEventListener('activated', (event) => {
	//			console.log( 'WB: activated' );
	//		});
	//		
	//		// Send a message telling the service worker to skip waiting.
	//		// This will trigger the `controlling` event handler above.
	//		// Note: for this to work, you have to add a message
	//		// listener in your service worker. See below.
	//		wb.messageSW({type: 'SKIP_WAITING'});
	
		})
		
		wb.addEventListener('installed', event => {
			console.log( 'WB: installed; isUpdate:', event.isUpdate );
	//		if (event.isUpdate) {
	//			window.location.reload();
	//		}
		});
		
		wb.register().then(registration => {
			console.log('SW registered: ', registration);
			
			if ( 'PushManager' in window && registration ) {
				pushProvider.initSw( registration.pushManager );
			}
		}).catch(registrationError => {
			console.log('SW registration failed: ', registrationError);
		});
	
	}
}

/**
 * aktualisiert einen wartenden Service Worker
 * 
 * @param url	(optional) nach update wechsel zu dieser Seite, sonst reload der aktuellen Seite
 */
export function	updateServiceWorker( url? : string ) {
	
	if ( wb ) {
		// `event.wasWaitingBeforeRegister` will be false if this is
		// the first time the updated service worker is waiting.
		// When `event.wasWaitingBeforeRegister` is true, a previously
		// updated same service worker is still waiting.
		// You may want to customize the UI prompt accordingly.
		
		// Assuming the user accepted the update, set up a listener
		// that will reload the page as soon as the previously waiting
		// service worker has taken control.
		wb.addEventListener('controlling', (event) => {
			console.log( 'Update WB: controlling' );
			if ( url ) {
				window.location.href = url;
			} else {
				window.location.reload();
			}
		});
		wb.addEventListener('activated', (event) => {
			console.log( 'Update WB: activated' );
		});
		
		// Send a message telling the service worker to skip waiting.
		// This will trigger the `controlling` event handler above.
		// Note: for this to work, you have to add a message
		// listener in your service worker. See below.
		wb.messageSW( {type: 'SKIP_WAITING'} );
	
		let spinner = new LoadingSpinner();
		spinner.caption = 'Updating';
		spinner.render();
	}	
}
