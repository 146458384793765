
/**
 * Resolves a promise in ms milliseconds
 */
export function delay( ms : number ) : Promise<void> {
	if ( ms > 0 ) {
		return new Promise( (resolve) => { 
			setTimeout(() => {
				resolve()
			}, ms );
		});
	}
	
	return Promise.resolve();
}

/**
 * Resolves a promise with setImmediate
 */
export function immediately( data? : unknown ) : Promise<unknown> {
	return new Promise( (resolve) => { 
		setImmediate( () => {
			resolve( data )
		});
	});
}

/**
 * Resolves a promise with requestAnimationFrame
 */
export function nextFrame() : Promise<void> {
	return new Promise( (resolve) => { 
		requestAnimationFrame( () => {
			resolve()
		});
	});
}
