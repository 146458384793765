import { AppStorage } from "../controllers/storage";
import { ApiProvider, IApiResponse } from "../api/api";


// web push public key
const applicationServerPublicKey = 'BNpStc0i9TXReWnkhLybLfpC1t0tpItpraeEtAzYzKmChC3OAyO0p1atOLkwh-8_TTTu_7iCI2xqMHdlRKhoVIY';

// from https://justmarkup.com/articles/2017-02-07-implementing-push-notifications/
function urlB64ToUint8Array( base64String : string ) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
	.replace(/-/g, '+')
	.replace(/_/g, '/');
	
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

interface IPushNotificationActionPerformed {
	actionId	: string
	notification: {
		id		: string
		data	: {
			url	: string
		}
	}
}

export class PushProvider extends ApiProvider {
	
	public action			: IPushNotificationActionPerformed
	
	public isSubscribed		: boolean = false;

	private swPushManager	: PushManager | undefined;
	
	
	private resolve			: (res : boolean) => void
	
	/**
	 * initialisation after service worker 
	 **/
	initSw( pushManager : PushManager ) {
		console.log('Initializing WebPush');
		
		this.swPushManager = pushManager;
		
		// Set the initial subscription value
		this.swPushManager.getSubscription()
		.then( (subscription) => {
			this.isSubscribed = (subscription !== null);
			
			if (this.isSubscribed) {
				console.log('User IS already subscribed to push.');
			} else {
				console.log('User is NOT subscribed to push.');
			}
			
			if ( this.resolve ) {
				this.resolve(this.isSubscribed);
			}
		});

		// we got a message from service worker push handling
		navigator.serviceWorker.addEventListener('message', (e) => {
			console.log( 'posted push message from service worker' )
			if ( e.data == 'push' ) {
				window.postMessage( 'push', '*' )
			}
		} );
	}

	// dummy init; push is initialised by service-worker registration
	init() : Promise<boolean> {
		return Promise.resolve(false)
	}
	
	isWebPushAvailable() : boolean {
		return !!this.swPushManager;
	}

	isAppPushAvailable() : boolean {
		return false;
	}
	
	// set subscription state on app start
	updateSubscriptionState( userid : string ) : Promise<void> {
		return Promise.resolve();
	}
	
	/** remove subscription */
	unsubscribe() : Promise<void> {
		return this.unsubscribeFromWebPush()
		.then( (endpoint) => {
			// send unsubscription to the server
			if ( endpoint ) {
				return this.submitUnsubscribe(endpoint)
				.then( () => {
					// unsubscription send
				} );
			}
			return Promise.resolve()			
		})
	}
	
	// restore the push-subscription state after login
	restoreSubscriptionState( userid : string ) : Promise<void> {
		
		if ( this.isWebPushAvailable() ) {
			return AppStorage.getItem( "rbl-push", userid )
			.then( (value) => {
				if ( value && value.subscribed ) {
					return AppStorage.removeItem( "rbl-push", userid )
					.then( () => {
						return this.subscribeToWebPush()
						.then( (subscription) => {
							// send subscription to the server
							return this.submitSubscription(subscription)
							.then( () => {
								// subscription send
							} );
						})
					})
				}
				return Promise.resolve()			
			})
		}
		return Promise.resolve();
	}

	// suspend the push subscription state on logout
	suspendSubscriptionState( userid : string ) : Promise<void> {
		
		// first store the push-subscriptions state of the current user (if exists)
		return this.storeSubscriptionState( userid )
		.then( (stored) => {
			if ( stored ) {
				// remove subscription from server
				return this.unsubscribe()
			}
			return
		})

	}
	
	// store the push-subscription state
	private storeSubscriptionState( userid : string ) : Promise<boolean> {
		
		if ( userid && this.isSubscribed ) {
			return AppStorage.setItem( "rbl-push", userid, { subscribed : true } )
			.then( () => {
				return true;
			})
		}
		return Promise.resolve( false );
	}
	
	private submitSubscription( subscription : PushSubscription ) : Promise<IApiResponse> {
		
		return this.fetchJson( this.request(
				'user/setting.php',
				{
					action: 'webpushSub',
				},
				{
					method	: 'POST',
					headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body	: "subscription=" + encodeURIComponent(JSON.stringify(subscription)),
				}
		) )
		.then( ( json : IApiResponse ) => {
			
			if ( json.xhrValue ) {
				return json
			} else {
				return Promise.reject<IApiResponse>( json.xhrMessages );
			}
			
		})
		
	}
	
	private submitUnsubscribe( endpoint : string ) : Promise<IApiResponse> {
		
		return this.fetchJson( this.request(
				'user/setting.php',
				{
					action: 'webpushUnsub',
				},
				{
					method	: 'POST',
					headers	: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body	: "endpoint=" + encodeURIComponent( endpoint ),
				}
		) )
		
	}
	
	private subscribeToWebPush() : Promise<PushSubscription> {
		const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
		
		if ( !this.swPushManager ) {
			return Promise.reject( 'Error subscribing to WebPush. PushManager is not available!' )
		}
		
		return this.swPushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: applicationServerKey
		})
		.then( (subscription) => {
			console.log('User is now subscribed to push.');
			
			this.isSubscribed = true;
			
			return subscription;
		})
	//	.catch(function(err) {
	//		console.log('Failed to subscribe the user: ', err);
	//		updateBtn();
	//	});
	}
	
	private unsubscribeFromWebPush() : Promise<string|void> {
		
		if ( !this.swPushManager ) {
			return Promise.reject( 'Error unsubscribing from WebPush. PushManager is not available!' )
		}
		
		return this.swPushManager.getSubscription()
		.then( (subscription) => {
			if (subscription) {
				
				let endpoint : string = subscription.endpoint;
				return subscription.unsubscribe()
				.then( (x) => {
					console.log('User is now unsubscribed from push.');
					return endpoint;
				})
			}
			
			this.isSubscribed = false;
			return '';
		})
	//	.catch(function(error) {
	//		console.log('Error unsubscribing from WebPush', error);
	//	})
	}
	
	/** add subscription */
	subscribe() : Promise<void> {
		return this.subscribeToWebPush()
		.then( (subscription) => {
			// send subscription to the server
			return this.submitSubscription(subscription)
			.then( () => {
				// subscription send
			} );
		})
	}

}
